// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend {
  margin-bottom: 35px;
}

.orange {
  background: rgb(227, 94, 36);
  display: inline-block;
  height: 12px;
  width: 30px;
  position: relative;
  top: 3px;
}

.blue {
  background: #779cc5;
  display: inline-block;
  height: 12px;
  width: 30px;
  position: relative;
  top: 3px;
}

h4 {
  color: rgb(227, 94, 36);
  font-weight: normal;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb25 {
  margin-bottom: 25px;
}

.pdt20 {
  padding-top: 20px;
}

.mb10 ul {
  margin-bottom: 17px;
  padding-inline-start: 17px;
}

::ng-deep #iacMainGraph {
  max-width: 100%;
}

@media screen and (max-device-width: 568px) {
  .messageBox.last {
    margin-bottom: 110px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/web/people-report-iac/people-report-iac.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,4BAAA;EACA,qBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,QAAA;AACJ;;AAEA;EACI,mBAAA;EACA,qBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,QAAA;AACJ;;AAEA;EACI,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AACA;EACI,mBAAA;AAEJ;;AACA;EACI,mBAAA;AAEJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,0BAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI;IACI,oBAAA;EACN;AACF","sourcesContent":[".legend {\n    margin-bottom: 35px;\n}\n\n.orange {\n    background: rgb(227, 94, 36);\n    display: inline-block;\n    height: 12px;\n    width: 30px;\n    position: relative;\n    top: 3px;\n}\n\n.blue {\n    background: #779cc5;\n    display: inline-block;\n    height: 12px;\n    width: 30px;\n    position: relative;\n    top: 3px;\n}\n\nh4 {\n    color: rgb(227, 94, 36);\n    font-weight: normal;\n}\n\n.mb5 {\n    margin-bottom: 5px;\n}\n.mb10 {\n    margin-bottom: 10px;\n}\n\n.mb25 {\n    margin-bottom: 25px;\n}\n\n\n.pdt20 {\n    padding-top: 20px;\n}\n\n.mb10 ul {\n    margin-bottom: 17px;\n    padding-inline-start: 17px;\n}\n\n::ng-deep #iacMainGraph {\n    max-width: 100%;\n}\n\n@media screen and (max-device-width: 568px) {\n    .messageBox.last {\n        margin-bottom: 110px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
