import {StateService} from '../../core/services/state/state.service';
import {Router} from '@angular/router';
import {first, take} from 'rxjs/operators';
import {WindowRef} from '../../app/services/window-ref.service';
import {environment} from '../../../environments/environment';
import {ApiPeopleService} from './api-people.service';
import {EvaluationHelper} from '../../shared/misc/evaluation.helper';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../shared/alert/services/alert.service';
import {Injectable, OnDestroy} from '@angular/core';
import {PersonService} from './person.service';
import {PricesDefinitions} from 'src/app/shared/prices/pricesDefinitions';
import {Content} from '../../shared/classes/services/content';
import {ApiJobsService} from '../../jobs/services/api-jobs.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';
import {EnvService} from '../../app/services/env.service';

@Injectable()
export class TestAndReportHandlingService extends Content implements OnDestroy {
    nativeWindow;
    evaluationFormUrl: string;
    subscriptions = new Subscription();

    constructor(
        protected stateService: StateService,
        protected router: Router,
        protected windowRef: WindowRef,
        protected apiPeopleService: ApiPeopleService,
        protected translateService: TranslateService,
        protected alertService: AlertService,
        protected person: PersonService,
        protected jobsService: ApiJobsService,
        protected deviceService: DeviceDetectorService,
        private envService: EnvService
    ) {
        super(router, deviceService, stateService);
        this.nativeWindow = windowRef.getNativeWindow();
        this.evaluationFormUrl = this.envService.evaluationFormUrl;
    }

    activateIacReportHandling() {
        return {
            id: this.person.id,
            subAccount: this.person.subAccount,
            types: ['iac'],
            cost: PricesDefinitions.item.iac,
            prbRelated: null,
            satelliteJobId: null
        };
    }

    activateMpoReportsHandling() {
        let cost = 0;
        let typeArr = [];
        let prbRelated: number | null = null;
        let satelliteJobId: string | null = null;

        for (let evaluation of ['communication', 'personality', 'talents', 'satellite', 'iac']) {
            // PRB related
            if (evaluation === 'personality') {
                prbRelated = EvaluationHelper.getPrbRelatedFromEvaluations(this.person.evaluations);
            }
            let reportType = evaluation;
            if (reportType === 'personality') {
                reportType = 'mpo';
            }
            if (reportType === 'communication') {
                reportType = 'dit';
            }

            if (
                this.person.evaluations[reportType] &&
                !this.person.evaluations[reportType].info.status
            ) {
                cost += PricesDefinitions.item[evaluation];
                typeArr.push(reportType);
                satelliteJobId = (evaluation === 'satellite') ? this.person.satelliteJobId : null;
            }
        }

        return {
            id: this.person.id,
            subAccount: this.person.subAccount,
            types: typeArr,
            cost: cost,
            prbRelated: prbRelated,
            satelliteJobId: satelliteJobId
        };
    }

    produceStatus(type = 'mpo') {
        if (type === 'mpo') {
            if (this.person.evaluations) {
                if (this.person.hasMpoRequest && !this.person.hasAnMpoReport) {
                    return this.translateService.instant('people.commons.notCompleted');
                } else if (this.person.hasPersonalityOnHold) {
                    return this.translateService.instant('commons.onHold');
                } else if (this.person.hasMpo) {
                    return this.translateService.instant('commons.completed');
                }
            }
        } else {
            if (this.person.hasIacRequest) {
                return this.translateService.instant('people.commons.notCompleted');
            } else if (this.person.hasPersonalityOnHold) {
                return this.translateService.instant('commons.onHold');
            } else if (this.person.hasIac) {
                return this.translateService.instant('commons.completed');
            }
        }
        return this.translateService.instant('commons.none');
    }

    /**
     * Brought from people.ts class
     * was getPrb(...)
     * @param prb
     * @param indexPrb
     * @param graphType
     */
    getPrbData(prb: any, indexPrb = 0, graphType = 'plain') {
        // No PRB
        if (!prb) {
            return {
                exists: false,
                graphType: 'inactive',
                scores: undefined
            };
        }

        // PRB data
        const hasPrev: boolean = (prb.info.size > 0 && indexPrb > 0);
        const hasNext: boolean = (prb.info.size > 0 && indexPrb < prb.info.lastPrbIndex);
        const prbData: any = prb.list[indexPrb];
        return {
            exists: true,
            graphType: graphType,
            currentIndex: indexPrb,
            hasPrev: hasPrev,
            prevIndex: indexPrb - 1,
            hasNext: hasNext,
            nextIndex: indexPrb + 1,
            data: prbData,
            date: (prbData && prbData.info && prbData.info.date) ? prbData.info.date : null,
            scores: (!(prbData && prbData.scores) || (graphType === 'inactive')) ? undefined : prbData.scores
        };
    }

    get extraPrbStatus() {
        let index = null;
        let extraPrbStatus = this.translateService.instant('commons.none');
        if (this.person.checkRequestsContains('variant', '0,1')) {
            extraPrbStatus = this.translateService.instant('people.commons.notCompleted');
        } else if (
            this.person.mpoCompletionDateToDisplay &&
            this.person.hasPrb
        ) {
            index = this.person.prbReport.list.length - 1;
            if (index !== 0 || (this.person.firstPrbCompletionDateToDisplay !== this.person.mpoCompletionDateToDisplay)) {
                if (this.person.prbReport.list[0].info.status) {
                    // If both aren't the same date, then there is an extra PCR
                    extraPrbStatus = this.translateService.instant('commons.completed');
                } else {
                    extraPrbStatus = this.translateService.instant('commons.onHold');
                }
            }
        }
        return extraPrbStatus;
    }

    // noinspection FunctionWithMoreThanThreeNegationsJS
    getReport(type: string,
              lang = 'fr',
              currentPrb: number = undefined
    ) {
        if (type !== 'personality' && type !== 'communication' && type !== 'satellite' && type !== 'talents' && type !== 'iac') {
            throw 'Inexistant report type';
        }
        if (type === 'personality') {
            let currentPrbPh = currentPrb || 0;
            // Correction for API calls :::::::
            type = 'mpo';
            return this.apiPeopleService.reportEvaluation(
                [type],
                [
                    this.person.id,
                    [1, 2, 3, 4, 'extraPoints'],
                    0, // Index of the evaluation object is (so far) always 0
                    currentPrbPh,
                    lang,
                    'analyst'
                ]
            );
        } else if (type === 'communication') {
            let currentPrbPh = currentPrb || 0;
            // Correction for API calls :::::::
            type = 'dit';
            return this.apiPeopleService.reportEvaluation(
                [type],
                [
                    this.person.id,
                    [1, 2, 3, 4],
                    0, // Index of the evaluation object is (so far) always 0
                    currentPrbPh,
                    lang,
                    0
                ]
            );
        } else if (type === 'satellite') {
            return this.apiPeopleService.reportEvaluationSatellite(
                [this.person.id],
                [
                    '0',
                    '0',
                    lang,
                    ['graph', 'personalityProfile', 'jobDescription']
                ]
            );
        } else if (type === 'talents') {
            let currentPrbPh = currentPrb || 0;
            return this.apiPeopleService.reportEvaluation(
                [type],
                [
                    this.person.id,
                    [3],
                    0, // Index of the evaluation object is (so far) always 0
                    currentPrbPh,
                    lang,
                    0
                ]
            );
        } else if (type === 'iac') {
            return this.apiPeopleService.reportRa(
                [this.person.id],
                [lang]
            );
        }
    }

    activateReportByParams(o, params, requiredGlobalNames = {activation: 'activation'}) {
        if (params.type === 'communication') {
            params.type = 'dit';
        }
        if (params.type === 'personality') {
            params.type = 'mpo';
        }
        this.subscriptions.add(this.apiPeopleService.product(
            [params.type],
            [params]
        ).subscribe(
            res => {
                this.person.setPersonToDisplayWithId(res.id, 'forceSidebarToReport');
                this.stateService.people.stateChanged.next({
                    'personReload': true
                });
            }
        ));
    }


    // TODO: This will be refactored. Still usefull??? - check if delete - refactoring
    testSwitchEvaluationType(newType, newSubType, archive, url, calledFrom) {
        let switchTo = (newType === 'communication' && newSubType === 'prb') ? 'communication' : newType;
        let isList = !!(calledFrom === 'list');
        let actionData: any = {
            action: '',
            data: {}
        };

        // Check permissions for `switchTo` report and stop processing if no access
        if (!this.sessionData.structure.testAccessPermissions[switchTo] && switchTo !== 'iac') { // TODO: remove last part when perms for iac activated
            actionData = {
                action: 'emit',
                data: {
                    variable: 'unaccessibleTest',
                    payload: switchTo
                }
            };
        } else {
            // has access to `switchTo` report, continue
            let action = '';
            let payload: any = {};

            // if hasMpoReport completed AND `switchTo` report doesn't exists and is not,
            // we need to activate this `switchTo` report
            if (
                this.person.hasAnMpoReport && switchTo !== 'iac' &&
                !(
                    this.person.evaluations[switchTo] &&
                    this.person.evaluations[switchTo].info.status
                )
            ) {
                if (!archive) {
                    if (this.sessionData.permissions.accountType === 5 && switchTo === 'communication') {
                        if (
                            !(this.person.personalityReport && this.person.personalityReport.info.status)
                            && !archive
                        ) {
                            let warningParagraph = this.translateService.instant('commons.reportActivationWarning');
                            let warningTexth1 = this.translateService.instant('commons.reportActivation');
                            actionData = {
                                action: 'alert',
                                data: {
                                    action: action,
                                    payload: {
                                        paragraph: warningParagraph,
                                        texth1: warningTexth1,
                                        template: 'prohibitedAction'
                                    }
                                }
                            };
                        } else {
                            let activationData = {
                                type: switchTo,
                                personToDisplay: {
                                    id: this.person.id, // actual in store :: this is what's displayed
                                    subAccount: this.person.subAccount
                                },
                                prbRelated: null
                            };

                            actionData = {
                                action: 'emit',
                                data: {
                                    variable: 'reportActivation',
                                    payload: activationData
                                }
                            };
                        }
                    } else {
                        // PRB related
                        let prbRelated: number | null = null;
                        if (switchTo === 'personality') {
                            prbRelated = EvaluationHelper.getPrbRelatedFromEvaluations(this.person.evaluations);
                        }

                        let activationData = {
                            type: switchTo,
                            personToDisplay: {
                                id: this.person.id, // actual in store :: this is what's displayed
                                subAccount: this.person.subAccount
                            },
                            prbRelated: prbRelated
                        };

                        actionData = {
                            action: 'emit',
                            data: {
                                variable: 'reportActivation',
                                payload: activationData
                            }
                        };
                    }
                }
                // if NOT(hasMpoReport completed AND `switchTo` report doesn't exists and is not),
                // we swhich display to `switchTo` report
            } else {
                if (isList) {
                    action = 'setReport';
                    payload = {
                        personToDisplayId: this.person.id,
                        reportType: switchTo
                    };
                } else {
                    action = 'getFullReport';
                    payload = {
                        personToDisplayId: this.person.id,
                        reportType: switchTo,
                        originReportType: newType
                    };
                }
                actionData = {
                    action: 'navigate',
                    data: {
                        action: action,
                        payload: payload
                    }
                };
            }
        }
        // Resolving with proper action
        if (actionData) {
            switch (actionData.action) {
                case 'emit':
                    return actionData.data;
                case 'alert':
                    this.alertService.setConfig(actionData.data);
                    break;
                case 'navigate':
                    if (this.person.reportOfType(switchTo)) {
                        this.stateService.navigate(this.router, url, actionData.data.action, actionData.data.payload);
                        break;
                    } else {
                        let checkType = switchTo;
                        if (checkType === 'iac') {
                            checkType = 'ra';
                        }
                        if (this.person.requests) {
                            let requestType = [];
                            this.person.requests.forEach(r => {
                                if (r && r.type) {
                                    r.type.forEach(type => {
                                        if (!requestType.includes(type)) {
                                            requestType.push(type);
                                        }
                                    });
                                }
                            });
                            if (requestType.includes(checkType)) {
                                // we have a request
                                return {requestType: checkType};
                            } else {
                                return {requestType: 'notFitting', checkType: requestType[0]};
                            }
                        } else {
                            // we have no request
                            return {requestType: 'noRequest', checkType: checkType};
                        }
                    }
                default:
                    break;
            }
        }
    }

    /**
     * Fetch satellite jobs
     * This is the right place for this method as the only place where we need that list
     * is in People's module.
     */
    satelliteJobs() {
        return this.jobsService.jobs([{
            'isSatellite': 1,
            'fields': 'id,jobTitle'
        }]);
    }

    prepareGetRequestSendData(type: string, evaluationRequest: number = null, isTempUser: boolean = null) {
        let emitter;
        if (type.includes('resend')) {
            let evaluationWindow = null;

            if (!isTempUser) {
                // Open window to avoid popup blocking
                evaluationWindow = this.nativeWindow.open('', '_blank');
            }

            return new Promise(resolve => {
                this.apiPeopleService.evaluationRequestResend([this.person.id, evaluationRequest]).pipe(first()).subscribe(
                    resendData => {
                        if (isTempUser) {
                            resolve({
                                emitter: 'requestResendEvent',
                                value: {
                                    'tempUser': resendData,
                                    'person': this.person,
                                    'type': type
                                }
                            });
                        } else {
                            evaluationWindow.document.location.href = this.evaluationFormUrl + '/autoconnect/' + resendData.token;
                        }

                    },
                    error => {
                        console.log(error);
                    });
            });
        } else {
            if (type === 'sendMpo') {
                emitter = 'requestSendMpoEvent';
            } else if (type === 'sendIac') {
                emitter = 'requestSendIacEvent';
            } else if (type === 'sendAdditionalPrb') {
                emitter = 'requestAdditionalPrbEvent';
            }
            return new Promise(resolve => {
                resolve({
                    emitter: emitter,
                    value: {'person': this.person}
                });
            });
        }

    }

    get sessionData() {
        if (
            this.stateService &&
            this.stateService.session &&
            this.stateService.session.sessionData
        ) {
            return this.stateService.session.sessionData;
        } else {
            return null;
        }

    }

    /** ToDo Presentation logic, to do later - refactoring **/
    deleteMpoRequest(requestId) {
        this.confirmRequestDelete(requestId, 'personality');
    }

    /**
     * renamed thus from deleteRaRequest
     * @param requestId
     */
    deleteIacRequest(requestId) {
        let requestType = this.translateService.instant('commons.cai');
        this.confirmRequestDelete(requestId, requestType);
    }

    confirmRequestDelete(requestId, requestType) {
        if (requestType === 'personality') {
            requestType = this.translateService.instant('commons.personality');
        }
        let txtDeletionConf = {
            paragraph: this.translateService.instant('commons.deleteRequestConfirmation', {qType: requestType}),
            texth1: 'Confirmation',
            position: 'center-center',
            template: 'warningConfirm'
        };

        this.alertService.setConfig(txtDeletionConf);
        this.alertService.nextAction$.subscribe(
            res => {
                if (res === 'confirm') {

                    this.person.loading = true;
                    this.apiPeopleService.deleteRequest(
                        [this.person.id],
                        {
                            subAccount: this.person.subAccount,
                            requestId: requestId
                        }).pipe(take(1)).subscribe(
                        () => {
                            this.stateService.people.stateChanged.next({
                                'refreshPage': true
                            });
                            this.person.setPersonToDisplayWithId(this.person.id, 'deleteRequest');
                            this.person.loading = false;
                            this.alertService.setConfig({
                                paragraph: this.translateService.instant('commons.questionnaireDeleted'),
                                texth1: this.translateService.instant('commons.deleted'),
                                position: 'top-right',
                                type: 'green'
                            });
                            // if the person hasn't completed questionnaire we have to delete the person at all
                            // and not just the requested not-completed questionnaire
                            if (!this.person.hasReport) {
                                this.apiPeopleService.deleteRecord([this.person.id]).subscribe(
                                    () => {
                                        this.person.resetPersonToDisplay();
                                        return;
                                    }
                                );
                                return;
                            }
                        }
                    );
                }
            }
        );
    }

    getRequestSendData(type: string, evaluationRequest: number = null, isTempUser: boolean = null) {
        this.prepareGetRequestSendData(type, evaluationRequest, isTempUser).then(
            () => {
                let wType = 'MPO';
                if (type === 'sendMpo') {
                    wType = this.translateService.instant('commons.cai');
                    // cType = 'MPO';
                }

                let txtWarning = {
                    paragraph: this.translateService.instant('commons.questionnaireAlreadySent', {qType: wType}),
                    texth1: this.translateService.instant('commons.alertWarning'),
                    position: 'center-center',
                    template: 'freezeAction'
                };
                if (type === 'sendIac' && this.person.hasAnMpoQuestionnaireSent) {
                    this.alertService.setConfig(txtWarning);
                } else if (type === 'sendMpo' && this.person.hasAnIacQuestionnaireSent) {
                    this.alertService.setConfig(txtWarning);
                } else {
                    if (type === 'sendIac') {
                        return {
                            act: true,
                            action: 'openRequestSendIacModal'
                        };
                    } else if (type === 'sendAdditionalPrb') {
                        return {
                            act: true,
                            action: 'openSendExtraPrbModal'
                        };
                    } else {
                        return {
                            act: true,
                            action: 'openRequestSendMpoModal'
                        };
                    }
                }
            }
        );
    }

    deletePrbRequest(requestId) {
        const txtDeletionConf = {
            paragraph: this.translateService.instant('commons.deleteRequestConfirmation', {qType: 'perception'}),
            texth1: 'Confirmation',
            position: 'center-center',
            template: 'warningConfirm'
        };
        this.alertService.setConfig(txtDeletionConf);
        this.alertService.nextAction$.subscribe(
            (res) => {
                if (res === 'confirm') {
                    this.person.loading = true;
                    this.apiPeopleService.deleteRequest(
                        [this.person.id], /** this is no longer useful. Passed to service **/
                        {
                            subAccount: this.person.subAccount,
                            requestId: requestId
                        }
                    ).pipe(take(1)).subscribe(
                        () => {
                            if (!this.person.prbReport?.info) {
                                this.person.prbReport = {
                                    info: {
                                        size: 0
                                    }
                                };
                            } else {
                                let prbReport = this.person.prbReport;
                                if (prbReport.size) {
                                    this.person.prbReport.info.size = prbReport.size--;
                                }
                            }
                            this.person.setPersonToDisplayWithId(this.person.id, 'deleteRequest');
                            this.person.loading = false;
                        }
                    );
                }
            });
    }

    satelliteText(score, lang = 'fr') {
        if (lang === 'fr') {
            if (score === 1) {
                return {
                    level: 'Bas',
                    text: 'Les caractéristiques de personnalité du participant ne sont pas en concordance avec celles' +
                        ' recherchées ' +
                        ' pour  le poste que vous avez sélectionné. Il y a très peu de chances que le candidat puisse répondre à vos ' +
                        ' besoins quand aux comportements exigés pour le poste.'
                };
            }

            if (score === 2) {
                return {
                    level: 'Moyen bas',
                    text: 'Les caractéristiques de personnalité du participant ne sont généralement pas en concordance avec celles ' +
                        ' recherchées pour le poste que vous avez sélectionné. Il y a de faibles chances que le candidat puisse ' +
                        ' répondre à vos besoins quand aux comportements exigés pour le poste.'
                };
            }

            if (score === 3) {
                return {
                    level: 'Moyen',
                    text: 'Plusieurs caractéristiques de personnalité du participant sont en concordance avec celles recherchées ' +
                        ' pour le poste que vous avez sélectionné. Il y a de bonnes chances que le candidat puisse répondre à vos ' +
                        ' besoins et de pouvoir adopter plusieurs des comportements exigés pour le poste.'
                };
            }

            if (score === 4) {
                return {
                    level: 'Moyen haut',
                    text: 'Plusieurs caractéristiques de personnalité du participant sont en concordance avec celles recherchées ' +
                        ' pour le poste que vous avez sélectionné. Il y a de bonnes chances que le candidat puisse répondre à vos ' +
                        ' besoins et de pouvoir adopter plusieurs des comportements exigés pour le poste.'
                };
            }

            if (score === 5) {
                return {
                    level: 'Haut',
                    text: 'La personnalité du participant est en forte concordance avec celle recherchée pour ' +
                        'le poste que vous avez sélectionné.  Le participant a d’excellentes chances de ' +
                        'répondre à vos besoins quant aux comportements exigés pour le poste.'
                };
            }
        } else if (lang === 'en') {
            if (score === 1) {
                return {
                    level: 'Low',
                    text: 'The participant’s personality traits are not at all aligned with those sought for the position in question. ' +
                        'There is very little chance that the candidate can meet your needs or demonstrate the right behaviors for ' +
                        'this position.'
                };
            }
            if (score === 2) {
                return {
                    level: 'Average Low',
                    text: 'The participant’s personality traits are generally not aligned with those sought for the position. There ' +
                        'is little chance that the candidate can meet your needs or demonstrate the right behaviors for this ' +
                        'position.'
                };
            }
            if (score === 3) {
                return {
                    level: 'Average',
                    text: 'Several of the participant’s personality traits are aligned with those sought for the position in question. ' +
                        'There is a good chance that the candidate can meet your needs and adopt several of the behavior requirements ' +
                        'for this position.'
                };
            }
            if (score === 4) {
                return {
                    level: 'Average High',
                    text: 'The participant’s personality is very well aligned with the personality type sought for the position in ' +
                        'question. There is a very good chance that the candidate can meet your needs and demonstrate the right ' +
                        'behaviors for this position.'
                };
            }
            if (score === 5) {
                return {
                    level: 'High',
                    text: 'The participant’s personality is exceptionally well aligned with the personality type sought for the position ' +
                        'in question. There is an excellent chance that this candidate can meet your needs and demonstrate the right ' +
                        'behaviors for this position.'
                };
            }
        }
        return;
    }

    renderQuad(quad: string) {
        let title = '';
        if (quad) {
            title = 'commons.communicationPortrait' + quad;
        }
        return title;
    }

    renderPortrait(portraitNumber, full = false) { // API sends stings not ints!
        let num = 0;
        if (portraitNumber) {
            num = +portraitNumber;
        }
        let title = '';
        switch (num) {
            // no portraitNumber case
            case 0 :
                title = 'commons.none';
                break;
            case 1000 :
                title = 'commons.notAvailable';
                break;
            case 1001 :
                title = 'commons.administrator';
                break;
            case 1002 :
                title = 'commons.ambassador';
                break;
            case 1003 :
                title = 'commons.analyst';
                break;
            case 1004 :
                title = 'commons.architect';
                break;
            case 1005 :
                title = 'commons.researcher';
                break;
            case 1006 :
                title = 'commons.designer';
                break;
            case 1007 :
                title = 'commons.diplomat';
                break;
            case 1008 :
                title = 'commons.controller';
                break;
            case 1009 :
                title = 'commons.collaborator';
                break;
            case 1010 :
                title = 'commons.coordinator';
                break;
            case 1011 :
                title = 'commons.techExpert';
                break;
            case 1012 :
                title = 'commons.explorer';
                break;
            case 1013 :
                title = 'commons.persuader';
                break;
            case 1014 :
                title = 'commons.facilitator';
                break;
            case 1015 :
                title = 'commons.driver';
                break;
            case 1016 :
                title = 'commons.realist';
                break;
            case 1017 :
                title = 'commons.intermediate';
                break;
            case 1018 :
                title = 'commons.idealist';
                break;
            case 1019 :
                title = 'commons.mediator';
                break;
            case 1020 :
                title = 'commons.organizer';
                break;
            case 1021 :
                title = 'commons.perfectionist';
                break;
            case 1022 :
                title = 'commons.philosopher';
                break;
            case 1023 :
                title = 'commons.pioneer';
                break;
            case 1024 :
                title = 'commons.planner';
                break;
            case 1025 :
                title = 'commons.presenter';
                break;
            case 1026 :
                title = 'commons.promoter';
                break;
            case 1027 :
                title = 'commons.relationist';
                break;
            case 1028 :
                title = 'commons.scientist';
                break;
            case 1029 :
                title = 'commons.socializer';
                break;
            case 1030 :
                title = 'commons.specialist';
                break;
            case 1031 :
                title = 'commons.supporter';
                break;
            case 1032 :
                title = 'commons.thinker';
                break;
            case 1033 :
                title = 'commons.charmer';
                break;
            case 1034 :
                title = 'commons.conscientious';
                break;
            case 1035 :
                title = 'commons.perseverant';
                break;
        }
        if (full) {
            return this.fullProfTitle(title, num);
        } else {
            return title;
        }
    }

    fullProfTitle(titleFlat, num): any {
        let title = titleFlat;
        switch (num) {
            case(1001):
                title = 'commons.administratorVar';
                break;
            case(1006):
                title = 'commons.designerVar';
                break;
            case(1007):
                title = 'commons.diplomatVar';
                break;
            case(1011):
                title = 'commons.techExpertVar';
                break;
            case(1019):
                title = 'commons.mediatorVar';
                break;
            case(1020):
                title = 'commons.organizerVar';
                break;
            case(1022):
                title = 'commons.philosopherVar';
                break;
            case(1024):
                title = 'commons.plannerVar';
                break;
            case(1025):
                title = 'commons.presenterVar';
                break;
            case(1027):
                title = 'commons.relationistVar';
                break;
            case(1028):
                title = 'commons.scientistVar';
                break;
            case(1029):
                title = 'commons.socializerVar';
                break;
            case(1032):
                title = 'commons.thinkerVar';
                break;
            case(1033):
                title = 'commons.charmerVar';
                break;
            case(1034):
                title = 'commons.conscientiousVar';
                break;
            case(1035):
                title = 'commons.perseverantVar';
                break;
        }
        return title;
    }

    get borderlineCase() {
        let borderlineCase = false;
        if (this.person.personalityScores &&
            this.person.personalityScores['A'] === 0 &&
            this.person.personalityScores['E'] === 0 &&
            this.person.personalityScores['P'] === 0 &&
            this.person.personalityScores['S'] === 0) {
            borderlineCase = true;
        }
        if (this.person.personalityScores &&
            this.person.personalityScores['A'] === 10 &&
            this.person.personalityScores['E'] === 10 &&
            this.person.personalityScores['P'] === 10 &&
            this.person.personalityScores['S'] === 10) {
            borderlineCase = true;
        }
        if (this.ditIsInvalid) {
            borderlineCase = true;
        }
        return borderlineCase;
    }

    get ditIsInvalid() {
        return (this.person.communicationScore === 'INV');
    }

    get statePeople(): any {
        return this.stateService.people;
    }

    get currentPrb(): any {
        return this.getPrbData(
            this.person.prbReport,
            this.stateService.people.prbIndex
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
