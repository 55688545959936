import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable()
export class AlertService {

    readonly defaultConfig: any = {
        ticker: -10, // dirtyCheck,
        backdrop: false, // a backdrop disable clicking behinf the alert
        type: null, // can be either of red yellow green
        texth1: null, // h1 title
        texth2: null, // h2 title
        paragraph: null, // text
        paragraph2: null, // text
        position: null, // positioning with possible values
                             // {top, center, bottom}-{left, center, right}
        template: null // presets
    };

    configWatch = new BehaviorSubject(this.defaultConfig);
    configWatch$ = this.configWatch.asObservable();

    nextAction = new BehaviorSubject(null);
    nextAction$ = this.nextAction.asObservable();

    private ticker: number;

    constructor() {
        this.ticker = 0;
    }

    setConfig(config) {
        this.ticker++;
        let newConfig = JSON.parse(JSON.stringify(this.defaultConfig));
        for (let key in config) {
            if (key in newConfig) {
                newConfig[key] = config[key];
            }
        }
        newConfig.ticker = this.ticker;
        this.configWatch.next(newConfig);
    }

}
