import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string, search: string): SafeHtml {
    if (!search) { return text; }

    // Escape special characters for use in the regex
    const escapedSearch = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(`^(${escapedSearch})`, 'gi'); // Ensure matching at the start

    // Replace matched text with <strong> tags
    const highlightedText = text.replace(regex, '<strong>$1</strong>');

    // Sanitize the HTML to prevent security risks
    return this.sanitizer.bypassSecurityTrustHtml(highlightedText);
  }
}