import {ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {StateService} from '../../../../../core/services/state/state.service';
import {ApiPeopleService} from 'src/app/people/services/api-people.service';
import {RightMatchAnalysisService} from '../../../services/right-match-analysis.service';
import {ApiRightMatchesService} from '../../../services/api-right-matches.service';
import {Router} from '@angular/router';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TranslateService} from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/alert/services/alert.service';

@Component({
    styleUrls: ['./right-matches-person-modal.component.scss'],
    selector: 'app-right-matches-person-modal',
    templateUrl: './right-matches-person-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightMatchesPersonModalComponent implements OnInit, OnDestroy {
    @Input() multiAction;

    @Output() multiActionDestroy = new EventEmitter();
    public close: EventEmitter<any> = new EventEmitter();

    searchTerm: string;
    records: Array<any>;
    selectedItems: Array<string>;
    rightMatchRecords: Array<string>;

    actual = '';

    public loading = false;

    chooseRightMatch: boolean;
    headerText: string;
    rmList: Array<any> = [];

    rmId: string;

    recordsLoaded = 100;
    recordsLength: number;

    private subscriptions = new Subscription();

    private numberAdded: number = 0;    // Tracks the number of additions
    private numberRemoved: number = 0;  // Tracks the number of removals
    private initialSelectedItems: string[] = []

    constructor(
        public rightMatchAnalysis: RightMatchAnalysisService,
        public bsModalRef: BsModalRef,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private stateService: StateService,
        private apiPeopleService: ApiPeopleService,
        private apiRightMatches: ApiRightMatchesService,
        public translateService: TranslateService,
        private alertService: AlertService,
    ) {}

    formatDateWithLang(date, lang) {
        if (date) {
            if (lang === 'fr') {
                return date.split('-').reverse().join('/');
            } else {
                return date.split('-')[1] + '/' + date.split('-')[2] + '/' + date.split('-')[0];
            }
        }
        return;
    }

    onSearch(event, searchTerm, which) {
        if (which === 'return' && event.keyCode === 13) {
            this.fetchRecords(searchTerm);
            this.actual = searchTerm;
        } else if (which === 'click') {
            this.fetchRecords(searchTerm);
            this.actual = searchTerm;
        }
    }

    clearSearch() {
        this.actual = '';
        this.searchTerm = '';
        this.fetchRecords(null);
    }

    ngOnInit() {
        this.fetchRecords();

        this.rightMatchRecords = [];
        this.selectedItems = [];
        if (this.multiAction && this.multiAction.ids.length > 0) {
            this.rightMatchRecords = this.multiAction.ids;

            this.chooseRightMatch = true;
            this.headerText = 'Les éléments choisis sont sélectionnés ci-dessous';

            this.loading = true;
            this.subscriptions.add(this.apiRightMatches.rightMatches([{limit: 100}]).subscribe(
                res => {
                    if (res) {
                        for (let rm of res.data) {
                            this.rmList.push({
                                id: rm.id,
                                name: rm.name
                            });
                        }

                        this.loading = false;
                    }
                }
            ));

        }

        if (this.rightMatchAnalysis.records) {
            this.rightMatchRecords = this.rightMatchRecords.concat(this.rightMatchAnalysis.records.map(record => record.id));
            // in case of the string array instead of the objects array
            if (this.rightMatchRecords.length && !this.rightMatchRecords[0]) {
                this.rightMatchRecords = this.rightMatchAnalysis.records;
            }
            this.selectedItems = this.rightMatchRecords;
        } else {
            this.rightMatchRecords = [];
            this.selectedItems = [];
        }
        this.initialSelectedItems = [...this.selectedItems];
    }

    assignRmId(val) {
        this.rightMatchAnalysis.setItemToDisplayWithId(val);
    }

    onChecked(id: string, event) {
        if (event.checked) {
            this.selectedItems.push(id);
        } else {
            this.selectedItems.splice(
                this.selectedItems.indexOf(id),
                1
            );
        }
    }

     /**
     * Display confirmation message in alert
     */
    private displayAlertMessage(): void {
        
        combineLatest([
            this.translateService.get('people.alert.successRequest.title', { numberAdded: this.numberAdded }),
            this.translateService.get('people.alert.individualsAdded', { numberAdded: this.numberAdded }),
            this.translateService.get('people.alert.individualsRemoved', { numberRemoved: this.numberRemoved })
        ]).pipe(take(1)).subscribe(([textH1, addedMsg, removedMsg]) => {
            const message = {
                texth1: textH1,
                paragraph: addedMsg,
                paragraph2: removedMsg,
                position: 'top-right',
                type: 'green'
            };
            this.alertService.setConfig(message);
        });
    }

    onSubmit() {
        if (this.selectedItems.length > 0) {
            this.numberAdded = this.selectedItems.filter(item => !this.initialSelectedItems.includes(item)).length;
            this.numberRemoved = this.initialSelectedItems.filter(item => !this.selectedItems.includes(item)).length;
            

            const data = { 'records': this.selectedItems };
            this.subscriptions.add(
                this.apiRightMatches.putRightMatch([this.rightMatchAnalysis.id], data).subscribe(
                    () => {
                        if (this.numberAdded > 0 || this.numberRemoved > 0) {
                            this.displayAlertMessage();
                            this.closeModal();
                        }
                    }
                )
            );
        }
    }

    closeModal(rmId= this.rightMatchAnalysis.id) {
        if (this.multiAction === true) {
            this.router.navigate(['/people/list']).then(() => {});
            return;
        }
        this.close.emit({closed: true, id: rmId});
        this.bsModalRef.hide();
    }

    fetchRecords(searchTerm = null, skip = null, concat = false) {
        this.loading = true;
        this.subscriptions.add(this.apiPeopleService.records(
            [{
                'search': searchTerm,
                'skip': skip,
                'isArchived': 0,
                'reportStatuses[mpo]': 1,
                'limit': this.recordsLoaded
            }]
        ).subscribe(
            (res) => {
                // Get records loaded (add the new records from API)
                this.recordsLoaded = (skip) ?
                    this.recordsLoaded + res.data.length :
                    this.recordsLoaded;

                // Records data (merge new records from API)
                this.records = (concat) ?
                    [...this.records, ...res.data] :
                    res.data;

                // Total records
                this.recordsLength = res.count;
                console.log(this.records);
                // Reload component
                this.changeDetectorRef.markForCheck();

                this.loading = false;
            }
        ));
    }

    onScroll() {
        if (this.recordsLoaded >= this.recordsLength) {

            return;
        }
        this.fetchRecords(this.searchTerm, this.recordsLoaded, true);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.close.emit({closed: true});
    }
}
