import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {ComponentSidebar} from '../../../../shared/classes/components/component_sidebar';
import {PersonService} from '../../../services/person.service';
import {StateService} from '../../../../core/services/state/state.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../../core/services/user/user.service';
import {TestAndReportHandlingService} from '../../../services/test-and-report-handling.service';
import {TextHelper} from '../../../../shared/misc/text.helper';
import {DeviceDetectorService} from 'ngx-device-detector';
import {PeopleModalHandlingService} from '../../../services/people-modal-handling.service';
import {ApiPeopleService} from '../../../services/api-people.service';
import {AlertService} from '../../../../shared/alert/services/alert.service';
import {first} from 'rxjs/operators';
import * as _ from 'lodash';
@Component({
    selector: 'app-people-sidebar',
    templateUrl: './people-sidebar.component.html',
    styleUrls: ['./people-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleSidebarComponent extends ComponentSidebar implements OnInit {

    @Input() isLeftAndNotTop = true;
    @Input() originReportType = '';
    @Input() width: string;
    @Input() config = 'list';
    @Input() selectedPersonName: string = '';
    @Input() selectedPersonScores: any = null;
    @Output() refreshList = new EventEmitter();
    @Output() openPeopleSidebar = new EventEmitter();

    noAccessTestsModal;

    public satelliteJobs;

    infoTestsVisibility = {
        mpo: false,
        prb: false,
        iac: false
    };

    showIacQuestionnaire = true;
    dropDownOpened = false;

    dateFormat;

    constructor(
        public person: PersonService,
        public user: UserService,
        public testAndReportHandling: TestAndReportHandlingService,
        public stateService: StateService,
        public translateService: TranslateService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected changeDetectorRef: ChangeDetectorRef,
        protected deviceService: DeviceDetectorService,
        public testAndReportHandlingService: TestAndReportHandlingService,
        public peopleModalHandlingService: PeopleModalHandlingService,
        public apiPeopleService: ApiPeopleService,
        public alertService: AlertService
    ) {
        super(stateService, translateService, router, deviceService);

    }

    ngOnInit() {
        this.dateFormat = TextHelper.dateFormat(this.translateService.currentLang);
        this.translateService.onLangChange.subscribe(
            (res) => {
                this.dateFormat = TextHelper.dateFormat(res.lang);
            }
        );
        this.titleSep = TextHelper.checkTitleSep(this.translateService.currentLang);
        // Falling back to an accessible (permitted) report type
        if (
            this.stateService.people.reportType === null ||
            this.stateService.people.reportType === undefined
        ) {
            for (let reportType of ['mpo', 'talents', 'dit', 'ra', 'satellite']) {
                if (this.stateService.session.sessionData.structure.testAccessPermissions[reportType]) {
                    switch (reportType) {
                        case 'mpo':
                            reportType = 'personality';
                            break;
                        case 'dit':
                            reportType = 'communication';
                            break;
                        case 'ra':
                            reportType = 'iac';
                            break;
                        default:
                            break;
                    }
                    this.switchReportType(reportType);
                    break;
                }
            }
        }
        this.testAndReportHandling.satelliteJobs().subscribe(
            (res) => {
                this.satelliteJobs = res.data;
                this.statePeople.stateChanged.next({'satelliteJobs': 'loaded'});
            }
        );

        this.stateService[this.specificState()].stateChanged$.subscribe(
            (res) => {
                if (res && res.personDefChanged) {
                    this.person.setPersonToDisplayWithId(this.person.id);
                }
                if (res && res.refreshReport) {
                    this.person.loading = false;
                }
                if (res && (res.refreshPage || res.refreshReport || res.personDeleted || res.deleteRequest)) {
                    this.refreshList.emit();
                }
                if (res && res.reportChange) {
                    this.switchReportTypeProper(this.stateService.people.reportType);
                    this.refreshList.emit();
                }
                this.changeDetectorRef.markForCheck();
            }
        );
        this.changeDetectorRef.markForCheck();
    }

    openPeopleEditModal() {
        this.peopleModalHandlingService.openPeopleEditModal();
        this.stateService.people.stateChanged$.subscribe(
            (res) => {
                if (res && res.refreshReport && res.id) {
                    this.refreshList.emit();
                }
            }
        );
    }

    checkMenu() {
        this.dropDownOpened = !this.dropDownOpened;
        setTimeout(() => {
            let dropdownMenu = document.getElementsByClassName('dropdown-menu')[0];
            dropdownMenu.addEventListener('click', function (event) {
                // The event won't be propagated up to the document NODE and
                // therefore delegated events won't be fired
                event.stopPropagation();
            });
        });
    }



    goToFullReport() {
        if (this.peopleModalHandlingService.checkReporUnaccessble()) {
            return;
        }

        if (this.actualModule) {
            switch (this.actualModule.name) {
                case 'people':
                    this.router.navigate([
                        'people/report/' +
                        this.person.id + '/' +
                        this.stateService.people.report.type
                    ]).then();
                    break;
                case 'archivesPeople':
                    break;
            }
        }
    }

    // Actions to prevent global scrolling when
    // scrolling on the sidebar

    scrollAction(e) {
        if (e) {
            e.preventDefault();
        }
    }

    touchmoveAction(e) {
        if (e) {
            e.preventDefault();
        }
    }

    openSidebar() {
        if (!this.closed) {
            setTimeout(() => {
                this.openPeopleSidebar.emit();
            });
        }
    }

    ///////////////////////////////////////////

    // Put in session service...
    setSubAccountName(subAccountNumber) {
        let name = null;
        if (subAccountNumber >= 0) {
            for (let subAccData of this.stateService.session.sessionData.structure.subAccounts) {
                if (subAccData.id === subAccountNumber) {
                    name = subAccData.name;
                    // noinspection BreakStatementJS
                    break;
                }
            }
        }
        return name;
    }

    // Navigation

    switchTab(value) {
        if (this.statePeople.selectedInfoQuestionTab !== value) {
            this.statePeople.informationVisibility = true;
            this.statePeople.selectedInfoQuestionTab = value;
            this.statePeople.stateChanged.next({selectedInfoQuestionTab: value});
        }
    }

    switchReportTab(value) {
        this.statePeople.reportTab = value;
        this.statePeople.stateChanged.next({reportTab: value});
    }

    toggleInfo(test) {
        if (test && this.infoTestsVisibility.hasOwnProperty(test)) {
            this.infoTestsVisibility[test] = !this.infoTestsVisibility[test];
        }
    }

    modStatus(action, act = null) {
        let p = [];
        let h1 = [];
        if (action === 'archive') {
            if (act) {
                this.apiPeopleService.checkDelete([this.person.id]).subscribe(
                    res => {
                        p[0] = this.translateService.instant('commons.archivedPerson');
                        h1[0] = this.translateService.instant('commons.archiving');
                        p[1] = this.translateService.instant('commons.tryAgain');
                        h1[1] = this.translateService.instant('commons.archivingError');

                        let pAction = this.translateService.instant('commons.archivingAction');
                        let paragraph;

                        if (Array.isArray(res) && res.length === 0) {
                            paragraph = this.translateService.instant(
                                'commons.personActionConfirmation',
                                {action: pAction, total: 1, plural: ''}
                            );
                        } else {
                            paragraph = this.translateService.instant(
                                'commons.personLinkedAnalysisConfirmation',
                                {action: pAction}
                            );
                        }
                        ///
                        let warnMessage = {
                            paragraph: '',
                            texth1: 'Confirmation',
                            position: 'center-center',
                            template: 'warningConfirm'
                        };


                        warnMessage.paragraph = paragraph;
                        this.alertService.setConfig(warnMessage);
                        this.alertService.nextAction$.subscribe(
                            // tslint:disable-next-line: no-shadowed-variable
                            res => {
                                if (res === 'confirm' && action) {
                                    this.apiPeopleService.editPerson([this.person.id], {archive: act}).subscribe(
                                        () => {
                                            this.alertService.setConfig({
                                                paragraph: p[0],
                                                texth1: h1[0],
                                                position: 'top-right',
                                                type: 'green'
                                            });
                                            if (this.config === 'list') {
                                                this.goToNews();
                                                this.refreshList.emit();
                                            } else {
                                                this.person.resetPersonToDisplay();
                                                this.goToList();
                                            }
                                            act = undefined;
                                            action = undefined;
                                        },
                                        () => {
                                            this.alertService.setConfig({
                                                paragraph: p[1],
                                                texth1: h1[1],
                                                position: 'center-center',
                                                type: 'red'
                                            });
                                        }
                                    );
                                }
                            }
                        );
                    });
            } else {
                let pAction = this.translateService.instant('commons.reactAction');
                p[0] = this.translateService.instant('commons.reactivatedPerson');
                h1[0] = this.translateService.instant('commons.reactivation');
                p[1] = this.translateService.instant('commons.tryAgain');
                h1[1] = this.translateService.instant('commons.reactError');

                const txtReactivationConf = {
                    paragraph: this.translateService.instant('commons.personActionConfirmation', {
                        action: pAction,
                        total: 1,
                        plural: ''
                    }),
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txtReactivationConf);
                this.alertService.nextAction$.subscribe(
                    res => {
                        if (res === 'confirm' && action) {
                            this.apiPeopleService.editPerson([this.person.id], {archive: act}).subscribe(
                                () => {
                                    this.alertService.setConfig({
                                        paragraph: p[0],
                                        texth1: h1[0],
                                        position: 'top-right',
                                        type: 'green'
                                    });
                                    if (this.config === 'list') {
                                        this.goToNews();
                                        this.refreshList.emit();
                                    } else {
                                        this.goToList();
                                    }
                                    act = undefined;
                                    action = undefined;
                                },
                                () => {
                                    this.alertService.setConfig({
                                        paragraph: p[1],
                                        texth1: h1[1],
                                        position: 'center-center',
                                        type: 'red'
                                    });
                                }
                            );
                        }
                    }
                );
            }
        } else if (action === 'delete') {
            this.apiPeopleService.checkDelete([this.person.id]).pipe(first()).subscribe(
                res => {
                    p[0] = this.translateService.instant('commons.personDeleted');
                    h1[0] = this.translateService.instant('commons.deleted');
                    p[1] = this.translateService.instant('commons.tryAgain');
                    h1[1] = this.translateService.instant('commons.wasError');

                    let txtDeletionConf = {
                        paragraph: '',
                        texth1: 'Confirmation',
                        position: 'center-center',
                        template: 'deleteUser'
                    };

                    let pAction = this.translateService.instant('commons.deletionAction');
                    let actionRes = this.translateService.instant('commons.actionRes');
                    let paragraph;
                    if (Array.isArray(res) && res.length === 0) {
                        paragraph = this.translateService.instant('commons.personActionConfirmation', {
                            action: pAction,
                            total: 1,
                            plural: ''
                        });
                    } else {
                        paragraph = this.translateService.instant('commons.personLinkedAnalysisConfirmation', {
                            action: pAction,
                            res: actionRes
                        });
                    }
                    txtDeletionConf.paragraph = paragraph;

                    this.alertService.setConfig(txtDeletionConf);

                    this.alertService.nextAction$.subscribe(
                        // tslint:disable-next-line: no-shadowed-variable
                        res => {
                            if (res === 'confirm' && action) {
                                this.apiPeopleService.deleteRecord([this.person.id]).pipe(first()).subscribe(
                                    () => {
                                        this.alertService.setConfig({
                                            paragraph: p[0],
                                            texth1: h1[0],
                                            position: 'top-right',
                                            type: 'green'
                                        });

                                        this.person.resetPersonToDisplay();

                                        if (this.config === 'list') {
                                            this.goToNews();
                                            this.refreshList.emit();
                                        } else {
                                            this.goToList();
                                        }
                                        action = undefined;
                                    }
                                );
                            }
                        }
                    );
                }
            );
        }
        return;
    }

    openModal(type, evaluationRequest = null, isTempUser = null) {
        this.testAndReportHandlingService.prepareGetRequestSendData(type, evaluationRequest, isTempUser).then(
            (data: any) => {
                let wType = 'MPO';
                if (type === 'sendMpo') {
                    wType = this.translateService.instant('commons.cai');
                    // cType = 'MPO';
                }

                let txtWarning = {
                    paragraph: this.translateService.instant('commons.questionnaireAlreadySent', {qType: wType}),
                    texth1: this.translateService.instant('commons.alertWarning'),
                    position: 'center-center',
                    template: 'freezeAction'
                };
                if (type === 'sendIac' && this.person.hasAnMpoQuestionnaireSent) {
                    this.alertService.setConfig(txtWarning);
                } else if (type === 'sendMpo' && this.person.hasAnIacQuestionnaireSent) {
                    this.alertService.setConfig(txtWarning);
                } else {
                    if (type === 'sendIac') {
                        return this.peopleModalHandlingService.openRequestSendIacModal({person: this.person.personToDisplay});
                    } else if (type === 'sendAdditionalPrb') {
                        return this.peopleModalHandlingService.openSendExtraPrbModal({person: this.person.personToDisplay});
                    } else if (type.includes('resend')) {
                        return this.peopleModalHandlingService.openRequestResendModal(data.value);
                    } else {
                        let forMpoOnly = this.person.hasIac;
                        return this.peopleModalHandlingService.openRequestSendMpoModal({
                            person: this.person.personToDisplay,
                            justMpo: forMpoOnly
                        });
                    }
                }
            }
        );
    }

    // Mutators


    get questionnaireBlockTitles() {

        let blocs = {
            first: {
                title: null,
                type: null
            },
            second: {
                title: this.translateService.instant('commons.additionalPrb')
            },
            third: {
                title: null
            }
        };
        if (this.person.hasAnMpoReport || this.person.hasAnIacReport) {
            // There is an mpo questionnaire filled
            if (this.person.hasMpo) {
                blocs.first.title = 'MPO';
                blocs.third.title = this.translateService.instant('commons.cai');
                // If MPO sent along IAC
                if (this.person.iacCompletionDate && this.person.mpoCompletionDateToDisplay === this.person.iacCompletionDateToDisplay) {
                    blocs.first.title = this.translateService.instant('commons.mpoCai');
                    this.showIacQuestionnaire = false;
                } else {
                    this.showIacQuestionnaire = true;
                }

                // First block type / if Prb and traits has been sent together
                blocs.first.type =
                    (
                        this.person.firstPrbCompletionDate &&
                        this.person.mpoCompletionDateToDisplay === this.person.firstPrbCompletionDateToDisplay
                    ) ? '1,1' : '1,0';
                // If a report est active, we may send another PCR so display the title
                if (this.person.hasAnMpoReport) {
                    blocs.second.title = this.translateService.instant('commons.additionalPrb');
                } // else, display nothing.

                // There is an iac questionnaire filled (and no MPO filled)
            } else {
                // First blocks title
                blocs.first.title = 'MPO'; // no MPO filled => no type
                blocs.third.title = this.translateService.instant('commons.cai');
                this.showIacQuestionnaire = true;
            }
            // Request in the pipeline
        } else {
            // MPO request?
            if (this.person.checkRequestsContains('typeContains', 'mpo')) {
                // check if the request contains also IAC
                blocs.first.title = 'MPO';
                blocs.third.title = this.translateService.instant('commons.cai');
                if (this.person.checkRequestsContains('typeContains', 'ra')) {
                    blocs.first.title = this.translateService.instant('commons.mpoCai');
                    this.showIacQuestionnaire = false;
                    // request contains only MPO
                } else {
                    this.showIacQuestionnaire = true;
                }
                blocs.first.type = (this.person.checkRequestsContainsMpoPrb()) ? '1,1' : '1,0';
                // Not MPO, must be IAC
            } else {
                blocs.first.title = 'MPO';
                if (this.person.hasAnMpoReport) {
                    blocs.second.title = this.translateService.instant('commons.additionalPrb');
                }
                blocs.third.title = this.translateService.instant('commons.cai'); // display this anyway, they are necessary apart
                this.showIacQuestionnaire = true;
            }
        }
        return blocs;
    }

    setPrbIndex(prbIndex: number) {
        // Set PRB index in state
        this.statePeople.prbIndex = prbIndex;
    }

    /**
     * Attention, aussi présent dans mobile... À factoriser.
     * @param which
     */
    translationItem(which: string) {
        if (which === 'date') {
            return TextHelper.dateFormat(this.translateService.currentLang);
        }
    }

    switchReportType(reportType, subType = null, from = null) {
        if (!this.peopleModalHandlingService.checkReporUnaccessble(reportType)) {
            if (reportType !== 'iac') {
                if (this.person.hasAnMpoReport && !this.person.hasEvaluationsType(reportType)) {
                    this.peopleModalHandlingService.openReportActivationModal({
                        id: this.person.id,
                        type: reportType
                    });
                } else if (this.person.hasNoMpo && !this.person.hasMpoOnHold && !this.person.hasAnIacQuestionnaireSent) {
                    // this.peopleModalHandlingService.openRequestSendMpoModal({person: this.person.personToDisplay});
                }
            } else if (reportType === 'iac' && this.config === 'report' && from === 'puck') {
                if (this.person.hasNoIac && !this.person.hasIacOnHold && !this.person.hasAnMpoQuestionnaireSent) {
                    this.peopleModalHandlingService.openRequestSendIacModal({person: this.person.personToDisplay});
                }
            }
            this.switchReportTypeProper(reportType, subType);
        }
    }

    switchReportTypeProper(reportType, subType = null) {
        this.statePeople.reportType = reportType;
        this.statePeople.reportSubType = subType;
        let stateChangedPayload: any = {
            'refreshPage': true
        };

        if (reportType === 'communication') {
            if (subType === 'prb') {
                stateChangedPayload['commPrb'] = true;
                this.stateService.people.stateChanged.next(stateChangedPayload);
            } else if (subType === 'traits' || subType === null) {
                stateChangedPayload['commTraits'] = true;
                this.stateService.people.stateChanged.next(stateChangedPayload);
            }
        }

    }

    onToggleSherpa(checked: boolean) {
        if (checked) {
            this.setInformationVisibility(false);
        } else {
            this.setInformationVisibility(true);
        }
        this.person.onToggleSherpa(this.person.id, this.stateService.people.report.type, checked);
    }

    isSherpaRoute() {
        return this.person.isSherpaRoute(this.person.id, this.stateService.people.report.type);
    }

    get currentPrb() {
        if (this.person.prbReport && this.person.prbReport.list) {
            let prbIndex = this.statePeople.prbIndex ? this.statePeople.prbIndex : 0;
            return this.testAndReportHandlingService.getPrbData(this.person.prbReport, prbIndex, this.mpoGraphType);
        }
        if (this.person.checkRequestsContains('variant', '1,1') ||
            this.person.checkRequestsContains('variant', '0,1')
        ) {
            let getPersonPRB = Object.assign({scores: 'request', dateSec: true}, this.person.requests[0]);
            if (getPersonPRB.date && getPersonPRB.date.sec) {
                getPersonPRB.date = getPersonPRB.date.sec;
            }
            return getPersonPRB;
        }
        return null;
    }

    get communicationPrb() {
        let additionalData = (_.get(this.currentPrb, 'info.additionalData'));
        if (!additionalData) {
            additionalData = (_.get(this.currentPrb, 'data.info.additionalData'));
        }
        return additionalData;
    }

    get communicationPrbScore() {
        let score = (_.get(this.communicationPrb, 'score'));
        if (!score) {
            score = (_.get(this.communicationPrb, 'dit.quad'));
        }
        return score;
    }

    get communicationPrbAd() {
        let adaptability = (_.get(this.communicationPrb, 'adaptability'));
        if (!adaptability) {
            adaptability = (_.get(this.communicationPrb, 'dit.adaptability'));
        }
        return adaptability;
    }

    get statePeople() {
        return this.stateService.people;
    }

    get satelliteIconTitle() {
        // Whether Satellite is on hold or has an MPO questionnaire but no satellite activated
        if (this.person.hasAnMpoQuestionnaireSent) {
            return {en: 'Resend', fr: 'Renvoyer', y: 33};
            // Has a satellite report but no score
        } else if (this.person.hasSatelliteOnHold || (this.person.hasAnMpoReport && !this.person.hasSatellite)) {
            return {en: 'Activate', fr: 'Activer', y: 33};
            // MPO questionnaire filled but not answered
        } else if (this.person.hasSatellite && (this.person.satelliteScore === 'noJobProfile' || !this.person.satelliteScore)) {
            return {en: 'Choose a ', fr: 'Choisir un ', secen: 'job', secfr: 'poste', y: 29};
        }
        return;
    }

    get standByIcon() {
        return {
            title: (this.translateService.currentLang === 'fr') ? 'En attente' : 'Standby',
            color: 'grayIcon',
            abr: (this.translateService.currentLang === 'fr') ? 'EA' : 'OH'
        };
    }

    get completedIcon() {
        return {
            title: (this.translateService.currentLang === 'fr') ? 'Complété' : 'Completed',
            color: 'orangeIcon',
            abr: 'C'
        };
    }

    get requestIcon() {
        return {
            title: (this.translateService.currentLang === 'fr') ? 'Non complété' : 'Not completed',
            color: 'darkGrayIcon',
            abr: 'NC'
        };
    }

    get defaultIcon() {
        return {
            title: (this.translateService.currentLang === 'fr') ? 'Aucun' : 'None',
            color: 'grayIcon',
            abr: 'N'
        };
    }

    get testStatusesMpoPersonality() {
        if (this.person.hasPersonalityOnHold) {
            return this.standByIcon;
        }
        if (this.person.hasPersonality) {
            return this.completedIcon;
        }
        if (this.person.checkRequestsContains('typeContains', 'mpo')) {
            return this.requestIcon;
        }
        return this.defaultIcon;
    }

    get testStatusesMpoCommunication() {
        if (this.person.hasCommunicationOnHold) {
            return this.standByIcon;
        }
        if (this.person.hasCommunication) {
            return this.completedIcon;
        }
        if (this.person.checkRequestsContains('typeContains', 'dit')) {
            return this.requestIcon;
        }
        return this.defaultIcon;
    }

    get testStatusesMpoSatellite() {
        if (this.person.hasSatelliteOnHold) {
            return this.standByIcon;
        }
        if (this.person.hasSatellite) {
            return this.completedIcon;
        }
        if (this.person.checkRequestsContains('typeContains', 'satellite')) {
            return this.requestIcon;
        }
        return this.defaultIcon;
    }

    get testStatusesMpoTalents() {
        if (this.person.hasTalentsOnHold) {
            return this.standByIcon;
        }
        if (this.person.hasTalents) {
            return this.completedIcon;
        }
        if (this.person.checkRequestsContains('typeContains', 'talents')) {
            return this.requestIcon;
        }
        return this.defaultIcon;
    }

    get testStatusesIac() {
        if (this.person.hasIacOnHold) {
            return this.standByIcon;
        }
        if (this.person.hasIac) {
            return this.completedIcon;
        }
        if (this.person.hasIacRequest) {
            return this.requestIcon;
        }
        return this.defaultIcon;
    }

    get communicationIconTitle() {
        if (this.testAndReportHandling.borderlineCase) {
            return {en: 'Invalid', fr: 'Invalide', y: 33};
        } else if (this.person.hasAnMpoQuestionnaireSent) {
            return {en: 'Resend', fr: 'Renvoyer', y: 33};
            // Has a satellite report but no score
        } else if (this.person.hasCommunicationOnHold || (this.person.hasAnMpoReport && !this.person.hasCommunication)) {
            return {en: 'Activate', fr: 'Activer', y: 33};
        }
        return;
    }

    get personalityIconTitle() {
        if (this.person.hasAnMpoQuestionnaireSent) {
            return {en: 'Resend', fr: 'Renvoyer', y: 33};
        }
        if (this.person.hasPersonalityOnHold || (this.person.hasAnMpoReport && !this.person.hasPersonality)) {
            return {en: 'Activate', fr: 'Activer', y: 33};
        }
        return;
    }

    get talentsIconTitle() {
        if (this.person.hasAnMpoQuestionnaireSent) {
            return {en: 'Resend', fr: 'Renvoyer', y: 33};
        }
        if (this.person.hasTalentsOnHold || (this.person.hasAnMpoReport && !this.person.hasTalents)) {
            return {en: 'Activate', fr: 'Activer', y: 33};
        }
        return;
    }

    get iacIconTitle() {
        if (this.person.hasAnIacQuestionnaireSent) {
            return {en: 'Resend', fr: 'Renvoyer', y: 33};
        }
        if (this.person.hasIacOnHold) {
            return {en: 'Activate', fr: 'Activer', y: 33};
        }
        return;
    }

    get prbToDisplay(): any {
        return this.testAndReportHandling.getPrbData(this.person.prbReport, this.statePeople.prbIndex, this.mpoGraphType);
    }

    get mpoGraphType() {
        if (!this.person.hasPersonality || (this.person.hasPersonality && !this.person.personalityScores)) {
            return 'inactive';
        }
        return (this.config === 'report') ? 'report' : 'plain';
    }

    get displayAssistant() {
        return this.user.hasJilAccess && this.router.url.includes('people/report');
    }
}

