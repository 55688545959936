import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import * as d3 from 'd3';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '../../../core/services/state/state.service';
import {PersonService} from '../../../people/services/person.service';

@Component({
    selector: 'app-mpo-graph',
    templateUrl: './mpo-graph.component.html',
    styleUrls: ['./mpo-graph.component.scss']
})
/**
 * This class contains 4 methods to draw a SVG graph. The rationale for separation is:
 * 1) A method for *global settings* of the SVG :: setting()
 * 2) A method for the *drawing* per se using the D3 module -- D3 methods and function can't be split
 *  between methods createMpoGraph()
 * 3) Two methods for *calculations*.
 */
export class MpoGraphComponent implements OnChanges {
    @ViewChild('MpoGraph', {static: true}) private chartContainer: ElementRef;

    // new
    @Input() id: string;
    @Input() width: any;
    @Input() section: string;
    @Input() scores: any;
    @Input() targetScore: any = false;
    @Input() mobile = false;
    @Input() messageOverhead = true;
    @Input() selectedPersonScore: any = false; 

    @Input() scSize = false; // scalable size
    @Input() comparisonScores: any = false; // if PRB
    @Input() requestArr: any;
    @Input() request = false;
    @Input() graphboard = false; // make true in graphBoars...
    @Input() report: any; // make true in report...
    @Input() rmScore: any = false; // make true in rightMatch...

    @Input() lang = 'fr';

    @Input() isUsedByDuo: boolean = false; // Make true in duo...

    hasScores = true;
    wasRequest = false;
    reportConfig = false;

    constructor(
        public person: PersonService,
        public translate: TranslateService,
        public stateService: StateService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        this.checkWidth(changes);

        this.resetInputs(changes);
        this.reportConfig = !!(this.report === 'report');
        if (this.section === 'personality' && this.person.personalityScores) {
            this.scores = this.person.personalityScores;
        }
        if (this.section === 'personPrb' && this.person.personalityScores) {
            this.scores = this.person.prbScores;
        }
        if (!this.scores || this.scores === 'request' || this.request) {
            this.hasScores = false;
            this.wasRequest = true;
            this.scores = {OR: 6, SE: 4, A: 3, E: 5, P: 7, S: 4};
        }

        // Clean scores (we need number between 0 to 10)
        this.scores = (this.hasScores) ? this.cleanScores(this.scores) : this.scores;

        this.scores.AD = (this.scores.A + this.scores.E + this.scores.P + this.scores.S) / 4;

        if (!this.comparisonScores && changes && changes.comparisonScores && !changes.comparisonScores.firstChange) {
            this.comparisonScores = changes.comparisonScores.previousValue;
        }
        if (this.comparisonScores) {
            this.comparisonScores.AD =
                (this.comparisonScores.A + this.comparisonScores.E + this.comparisonScores.P + this.comparisonScores.S) / 4;
        }

        if (this.section === 'rightMatch' || this.section === 'jobSatisfaction') {
            if (changes && changes.id && changes.id.previousValue) {
                d3.select('#' + changes.id.previousValue).remove();
            }
        }

        this.createMpoGraph();
        this.updateHasScores();
    }

    updateHasScores() {
        if (this.isUsedByDuo) {
            this.hasScores = !!this.scores || !!this.selectedPersonScore;
        } else {
            this.hasScores = !!this.scores;
        }
    }

    checkWidth(changes) {
        if (changes && changes.id && changes.id.currentValue === 'profiler-modal') {
            this.width = '100%';
        } else if (this.mobile) {
            if (!this.width) {
                this.width = window.innerWidth + 'px';
            }
        } else {
            if (!this.width) {
                this.width = '400px';
            }
        }
    }

    /**
     * This method draw MPO graph in all possible blends accordind to Input() type param:
     * 1) type==plain :: Generic plain MPO graph for lists and profiler.
     * 2) type==report :: MPO graphs used in reports that display the deltas of the interactions and between PRB and Trait.
     * 3) type==inactive :: Inactive graphs.
     *
     * @returns {Selection<HTMLElement, any>} - the SVG definition
     */

    createMpoGraph() {

        let element = this.chartContainer.nativeElement;
        let tWidth: any = 0;
        let limitOuter = 0;
        let limitInner = 0;
        let buffer = 0;
        let measure = false;
        
        let settings = this.setting();

        if (this.mobile && !this.width) {
            this.width = window.innerWidth + 'px';
        }

        if (this.width && this.width !== '100%' && !this.graphboard) {
            measure = true;
            limitOuter = 440;
            limitInner = 400;
            buffer = 40;
            tWidth = this.width.substring(0, this.width.length - 2);

            if (this.graphboard) {
                tWidth /= 3;
            }

            if (this.mobile) {
                limitOuter = parseInt(this.width, 10) + 40;
                limitInner = parseInt(this.width, 10);
            }
        }

        d3.select('#' + this.id).remove();

        let svg;

        if (measure) {
            let outerWidth =  (tWidth < limitOuter) ? tWidth - buffer : limitInner;
            // tslint:disable-next-line: no-shadowed-variable
            let rat = settings.yLimit / settings.xLimit;

            d3.select('#' + this.id).remove();

            if (!this.scSize) {
                svg = d3.select(element).append('svg').attr('id', this.id)
                    .attr('height', outerWidth * rat + 'px')
                    .attr('width', outerWidth + 'px')
                    .attr('viewBox', '0 0 ' + settings.xLimit + ' ' + settings.yLimit)
                    .attr('preserveAspectRatio', 'xMidYMid meet');
            } else {
                svg = d3.select(element).append('svg').attr('id', this.id)
                    .attr('width', '100%')
                    .attr('viewBox', '0 0 ' + settings.xLimit + ' ' + settings.yLimit)
                    .attr('preserveAspectRatio', 'xMidYMid meet');
            }
        } else {
            svg = d3.select(element).append('svg').attr('id', this.id)
                .attr('viewBox', '0 0 ' + settings.xLimit + ' ' + settings.yLimit)
                .attr('preserveAspectRatio', 'xMidYMid meet');
        }

        svg.style('background-color', settings.color.outerBorder);

        // Type-specific borders information
        if (this.reportConfig || this.rmScore) {
            if (this.rmScore) {
                let endAngle = (this.rmScore === 0) ?
                    0.1 :
                    2 * Math.PI * this.rmScore / 5;
                let arcBG = d3.arc().innerRadius(70).outerRadius(90).startAngle(0).endAngle(2 * Math.PI);
                let arc = d3.arc().innerRadius(70).outerRadius(90).startAngle(0).endAngle(endAngle);

                // Left Framing
                svg.append('rect').attr('x', 10).attr('y', 10).attr('width', settings.xLimit - 305).
                    attr('height', settings.yLimit - 20).
                    style('fill', 'white');

                // Right Framing
                svg.append('rect').attr('x', settings.xLimit - 285).attr('y', 75).attr('width', 275).
                    attr('height', settings.yLimit - 85).
                    style('fill', 'white');

                svg.append('text').attr('x', settings.xLimit - 230).attr('y', 45).text('Concordance').
                    attr('font-family', settings.fonts.general.family).
                    attr('font-weight', 'bold').attr('font-size', settings.fonts.general.size).
                    attr('fill', settings.color.darkGray);

                svg.append('text').attr('x', 925).
                    attr('y', 235).
                    text(this.rmScore).
                    attr('text-anchor', 'middle').
                    attr('font-family', 'sans-serif').
                    attr('font-size', 50).
                    attr('fill', 'rgb(227, 94, 36)');

                svg.append('path').attr('transform', 'translate(925,220)').attr('class', 'arc').attr('d', arcBG).
                    attr('fill', '#EEE');

                svg.append('path').attr('transform', 'translate(925,220)').attr('class', 'arc').attr('d', arc).
                    attr('fill', '#AAA');

            } else {

                // Left Framing
                svg.append('rect').attr('x', 10).attr('y', 10).attr('width', settings.xLimit - 175).
                    attr('height', settings.yLimit - 20).
                    style('fill', 'white');

                // Right Framing
                svg.append('rect').attr('x', settings.xLimit - 155).attr('y', 75).attr('width', 145).
                    attr('height', settings.yLimit - 85).
                    style('fill', 'white');

                if (!this.comparisonScores) {
                    // Interaction display
                    let orderedInteractions = this.orderInteraction(this.scores);

                    // Analysis text
                    let analyse = (this.translate.currentLang === 'fr') ? 'Analyse' : 'Analysis';

                    let xAlignAnalyse = (this.translate.currentLang === 'fr') ? 131 : 134;
                    svg.append('text').attr('x', settings.xLimit - xAlignAnalyse).attr('y', 45).text(analyse).
                        attr('font-family', settings.fonts.general.family).
                        attr('font-weight', 'bold').attr('font-size', settings.fonts.general.size).
                        attr('fill', settings.color.darkGray);

                    // Display the interactions
                    orderedInteractions.forEach((interaction, i) => {
                        svg.append('text').attr('x', settings.xLimit - 115).attr('y', 115 + i * 44).
                            text(interaction[0] + ' ' + interaction[1]).
                            attr('font-family', settings.fonts.general.family).attr('font-size', settings.fonts.general.size).
                            attr('fill', settings.color.darkGray);
                    });

                } else {

                    let orderedDeltas = this.orderDeltasPrbAndTraits(this.scores, this.comparisonScores);
                    let variation = (this.translate.currentLang === 'fr') ? 'Écart' : 'Variation'; // Écart texte
                    let xAlignVariation = (this.translate.currentLang === 'fr') ? 115 : 135; // Écart texte

                    svg.append('text').attr('x', settings.xLimit - xAlignVariation ).attr('y', 45).text(variation).
                        attr('font-family', settings.fonts.general.family).
                        attr('font-weight', 'bold').attr('font-size', settings.fonts.general.size).
                        attr('fill', settings.color.darkGray);

                    orderedDeltas.forEach((deltas, i) => {
                        if (deltas[0] === 'SE' && this.translate.currentLang === 'en') {
                            deltas[0] = 'ES';
                        }
                        svg.append('text').attr('x', settings.xLimit - 117)// + correction[i])
                            .attr('y', 115 + i * 37).text(deltas[0]) // + " " + deltas[1]
                            .attr('text-anchor', 'middle').attr('font-family', settings.fonts.general.family).
                            attr('font-size', settings.fonts.general.size).
                            attr('fill', settings.color.darkGray);

                        // If this is a Request
                        if (this.request) { deltas[1] = '–'; }

                        svg.append('text').attr('x', settings.xLimit - 57)// + correction[i])
                            .attr('y', 115 + i * 37).text(deltas[1]) // + " " + deltas[1]
                            .attr('text-anchor', 'middle').attr('font-family', settings.fonts.general.family).
                            attr('font-size', settings.fonts.general.size).
                            attr('fill', settings.color.darkGray);
                    });
                }
            }
        } else {
            // Framing
            svg.append('rect').attr('x', 10).attr('y', 10).attr('width', settings.xLimit - 20).attr('height', settings.yLimit - 20).style('fill', 'white');
        }

        const scoreMarkersXPos = [110, 170, 230, 290, 350, 410, 470, 530, 590, 650, 710];
        const scoresTextXPos = [103, 163, 223, 283, 344, 404, 464, 524, 584, 644, 697];
        const standardDeviationXPos = [200, 320, 500, 620];

        let SE_Text = (this.lang === 'fr') ? 'SE' : 'ES'; // Language vatiations
        let SEHorz = scoreMarkersXPos[this.scores.SE] - 3.9 + settings.xTranslation; // ES Specific correction

        // Draw //////////////////////////////////////////

        // Stardard deviation markers

        for (let j = 0; j < 4; j++) {
            svg.append('line').attr('x1', standardDeviationXPos[j] + settings.xTranslation).
                attr('y1', 56 + settings.yTranslation).
                attr('x2', standardDeviationXPos[j] + settings.xTranslation).attr('y2', 259 + settings.yTranslation).
                attr('stroke-width', 3).
                attr('stroke', settings.color.paleGray);
        }

        // Bold lines for ES, OR and AD

        // Bold score guidelines

        let scoreGuidelines = [13, 47, 224];

        scoreGuidelines.forEach(y => {
            svg.append('rect').attr('class', 'scoreGuidelines').attr('y', y + settings.yTranslation).
                attr('x', 89 + settings.xTranslation).
                attr('width', 644).attr('height', 10).style('fill', settings.color.paleGray);
        });

        // Lines

        svg.append('line').attr('x1', 89 + settings.xTranslation).attr('y1', 259 + settings.yTranslation).
            attr('x2', 734 + settings.xTranslation).
            attr('y2', 259 + settings.yTranslation).
            attr('stroke-width', settings.graphics.bottomScoreLineStroke).
            attr('stroke', settings.color.black);

        // Bootom score lines

        for (let i = 0; i < 11; i++) {
            let coord = 110 + i * 60;
            svg.append('line').attr('x1', coord + settings.xTranslation).attr('y1', 259 + settings.yTranslation).
                attr('x2', coord + settings.xTranslation).
                attr('y2', 269 + settings.yTranslation).
                attr('stroke-width', settings.graphics.bottomScoreLineStroke).
                attr('stroke', settings.color.black);
        }

        // Score texxts

        let scoreSaleTexts = [
            {x: 30 + settings.xTranslation, y: 28 + settings.yTranslation, text: 'OR'},
            {x: 32 + settings.xTranslation, y: 62 + settings.yTranslation, text: SE_Text},
            {x: 38 + settings.xTranslation, y: 96 + settings.yTranslation, text: 'A'},
            {x: 38 + settings.xTranslation, y: 130 + settings.yTranslation, text: 'E'},
            {x: 38 + settings.xTranslation, y: 164 + settings.yTranslation, text: 'P'},
            {x: 38 + settings.xTranslation, y: 198 + settings.yTranslation, text: 'S'},
            {x: 30 + settings.xTranslation, y: 238 + settings.yTranslation, text: 'AD'}
        ];

        scoreSaleTexts.forEach(scaleText => {
            svg.append('text').attr('class', 'scaleText').attr('x', scaleText.x).attr('y', scaleText.y).text(scaleText.text).
                attr('fill', settings.color.black);
            }
        );

        d3.selectAll('.scaleText').attr('font-family', settings.fonts.general.family).
            attr('font-size', settings.fonts.general.size);

        // Score's value texts

        for (let k = 0; k < 11; k++) {
            svg.append('text').attr('x', scoresTextXPos[k] + settings.xTranslation).attr('y', 295 + settings.yTranslation).
                text(k).attr('font-family', settings.fonts.general.family).attr('font-size', settings.fonts.general.size).
                attr('fill', settings.color.black);
        }

        // OR and ES score indicators

        if (this.targetScore) {
            let TargetSEHorz = scoreMarkersXPos[this.targetScore.SE] - 3.9 + settings.xTranslation; // ES Specific correction

            svg.append('circle').attr('class', 'TargetORESIndicators').
            attr('cx', scoreMarkersXPos[this.targetScore.OR] + settings.xTranslation).attr('cy', 17.75 + settings.yTranslation).
            attr('r', 10).style('fill', settings.color.paleGray2);

            svg.append('rect').attr('class', 'TargetORESIndicators').attr('x', TargetSEHorz - 5).attr('y', 43 + settings.yTranslation).
            attr('width', 18).attr('height', 18).style('fill', settings.color.paleGray2);
        }

        if (this.selectedPersonScore) {
            let selectedPersonScoreSEHorz = scoreMarkersXPos[this.selectedPersonScore.SE] - 3.9 + settings.xTranslation; // ES Specific correction

            svg.append('circle').attr('class', 'TargetORESIndicators').
            attr('cx', scoreMarkersXPos[this.selectedPersonScore.OR] + settings.xTranslation).attr('cy', 17.75 + settings.yTranslation).
            attr('r', 10).style('fill', settings.color.paleGray2);

            svg.append('rect').attr('class', 'TargetORESIndicators').attr('x', selectedPersonScoreSEHorz - 5).attr('y', 43 + settings.yTranslation).
            attr('width', 18).attr('height', 18).style('fill', settings.color.paleGray2);
        }

        svg.append('circle').attr('class', 'ORESIndicators').
            attr('cx', scoreMarkersXPos[this.scores.OR] + settings.xTranslation).attr('cy', 17.75 + settings.yTranslation).
            attr('r', 10).style('fill', settings.color.ngOrange);

        svg.append('rect').attr('class', 'ORESIndicators').attr('x', SEHorz - 5).attr('y', 43 + settings.yTranslation).
            attr('width', 18).attr('height', 18).style('fill', settings.color.ngOrange);

        // CENTRAL GRAPH ////////////////////////////////

        if (this.targetScore) {
            // Score dots (A, E, P, S)

            let targetScoreDots = [
                {cx: scoreMarkersXPos[this.targetScore.A] + settings.xTranslation, cy: 90 + settings.yTranslation},
                {cx: scoreMarkersXPos[this.targetScore.E] + settings.xTranslation, cy: 124 + settings.yTranslation},
                {cx: scoreMarkersXPos[this.targetScore.P] + settings.xTranslation, cy: 158 + settings.yTranslation},
                {cx: scoreMarkersXPos[this.targetScore.S] + settings.xTranslation, cy: 192 + settings.yTranslation}
            ];

            targetScoreDots.forEach(sDots => {
                svg.append('circle').attr('class', 'targetScoreDots').attr('cx', sDots.cx).attr('cy', sDots.cy).attr('r', 10).
                attr('fill', settings.color.paleGray2);
            });

            d3.selectAll('.targetScoreDots')
            ;

            // Interaction lines: A-E, E-P & P-S

            let targetInteractionLines = [
                {
                    x1: scoreMarkersXPos[this.targetScore.A] + settings.xTranslation, y1: 90 + settings.yTranslation,
                    x2: scoreMarkersXPos[this.targetScore.E] + settings.xTranslation, y2: 124 + settings.yTranslation
                },
                {
                    x1: scoreMarkersXPos[this.targetScore.E] + settings.xTranslation, y1: 124 + settings.yTranslation,
                    x2: scoreMarkersXPos[this.targetScore.P] + settings.xTranslation, y2: 158 + settings.yTranslation
                },
                {
                    x1: scoreMarkersXPos[this.targetScore.P] + settings.xTranslation, y1: 158 + settings.yTranslation,
                    x2: scoreMarkersXPos[this.targetScore.S] + settings.xTranslation, y2: 192 + settings.yTranslation
                }
            ];

            targetInteractionLines.forEach(intLine => {
                svg.append('line').attr('class', 'targetInteractionLines').attr('x1', intLine.x1).attr('y1', intLine.y1).
                attr('x2', intLine.x2).attr('y2', intLine.y2).attr('stroke-width', 2).attr('stroke', settings.color.paleGray2);
            });
        }

        if (this.selectedPersonScore) {
            // Score dots (A, E, P, S)

            let selectedPersonDots = [
                {cx: scoreMarkersXPos[this.selectedPersonScore.A] + settings.xTranslation, cy: 90 + settings.yTranslation},
                {cx: scoreMarkersXPos[this.selectedPersonScore.E] + settings.xTranslation, cy: 124 + settings.yTranslation},
                {cx: scoreMarkersXPos[this.selectedPersonScore.P] + settings.xTranslation, cy: 158 + settings.yTranslation},
                {cx: scoreMarkersXPos[this.selectedPersonScore.S] + settings.xTranslation, cy: 192 + settings.yTranslation}
            ];

            selectedPersonDots.forEach(sDots => {
                svg.append('circle').attr('class', 'selectedPersonDots').attr('cx', sDots.cx).attr('cy', sDots.cy).attr('r', 10).
                attr('fill', settings.color.paleGray2);
            });

            d3.selectAll('.selectedPersonDots')
            ;

            // Interaction lines: A-E, E-P & P-S

            let selectedPersonInteractionLines = [
                {
                    x1: scoreMarkersXPos[this.selectedPersonScore.A] + settings.xTranslation, y1: 90 + settings.yTranslation,
                    x2: scoreMarkersXPos[this.selectedPersonScore.E] + settings.xTranslation, y2: 124 + settings.yTranslation
                },
                {
                    x1: scoreMarkersXPos[this.selectedPersonScore.E] + settings.xTranslation, y1: 124 + settings.yTranslation,
                    x2: scoreMarkersXPos[this.selectedPersonScore.P] + settings.xTranslation, y2: 158 + settings.yTranslation
                },
                {
                    x1: scoreMarkersXPos[this.selectedPersonScore.P] + settings.xTranslation, y1: 158 + settings.yTranslation,
                    x2: scoreMarkersXPos[this.selectedPersonScore.S] + settings.xTranslation, y2: 192 + settings.yTranslation
                }
            ];

            selectedPersonInteractionLines.forEach(intLine => {
                svg.append('line').attr('class', 'selectedPersonInteractionLines').attr('x1', intLine.x1).attr('y1', intLine.y1).
                attr('x2', intLine.x2).attr('y2', intLine.y2).attr('stroke-width', 2).attr('stroke', settings.color.paleGray2);
            });
        }

        // Score dots (A, E, P, S)

        let scoreDots = [
            {cx: scoreMarkersXPos[this.scores.A] + settings.xTranslation, cy: 90 + settings.yTranslation},
            {cx: scoreMarkersXPos[this.scores.E] + settings.xTranslation, cy: 124 + settings.yTranslation},
            {cx: scoreMarkersXPos[this.scores.P] + settings.xTranslation, cy: 158 + settings.yTranslation},
            {cx: scoreMarkersXPos[this.scores.S] + settings.xTranslation, cy: 192 + settings.yTranslation}
        ];

        scoreDots.forEach(sDots => {
            svg.append('circle').attr('class', 'scoreDots').attr('cx', sDots.cx).attr('cy', sDots.cy).attr('r', 10).
                attr('fill', settings.color.darkGray);
        });

        d3.selectAll('.scoreDots')
        ;

        // Interaction lines: A-E, E-P & P-S

        let interactionLines = [
            {
                x1: scoreMarkersXPos[this.scores.A] + settings.xTranslation, y1: 90 + settings.yTranslation,
                x2: scoreMarkersXPos[this.scores.E] + settings.xTranslation, y2: 124 + settings.yTranslation
            },
            {
                x1: scoreMarkersXPos[this.scores.E] + settings.xTranslation, y1: 124 + settings.yTranslation,
                x2: scoreMarkersXPos[this.scores.P] + settings.xTranslation, y2: 158 + settings.yTranslation
            },
            {
                x1: scoreMarkersXPos[this.scores.P] + settings.xTranslation, y1: 158 + settings.yTranslation,
                x2: scoreMarkersXPos[this.scores.S] + settings.xTranslation, y2: 192 + settings.yTranslation
            }
        ];

        interactionLines.forEach(intLine => {
            svg.append('line').attr('class', 'interactionLines').attr('x1', intLine.x1).attr('y1', intLine.y1).
                attr('x2', intLine.x2).attr('y2', intLine.y2).attr('stroke-width', 2).attr('stroke', settings.color.darkGray);
        });

        // AD Marker
        if (this.targetScore) {
            let fracTarget = this.targetScore.AD - Math.floor(this.targetScore.AD);
            let ADCorrectionTarget = 60 * fracTarget;

            let ADPreTarget = scoreMarkersXPos[Math.floor(this.targetScore.AD)] + ADCorrectionTarget;

            let ADPosTarget = [
                ADPreTarget - 16,
                ADPreTarget,
                ADPreTarget + 16
            ];

            let polyTarget = [
                {'x': ADPosTarget[0] + settings.xTranslation, 'y': 234 + settings.yTranslation},
                {'x': ADPosTarget[1] + settings.xTranslation, 'y': 217 + settings.yTranslation},
                {'x': ADPosTarget[2] + settings.xTranslation, 'y': 234 + settings.yTranslation}
            ];

            svg.append('polygon').data([polyTarget]).attr('points', function (d) {
                // tslint:disable-next-line: no-shadowed-variable
                return d.map(function (d) {
                    return [d.x, d.y].join(',');
                }).join(' ');
            }).style('fill', settings.color.paleGray2);
        }

        if (this.selectedPersonScore) {
            let fracTarget = this.selectedPersonScore.AD - Math.floor(this.selectedPersonScore.AD);
            let ADCorrectionTarget = 60 * fracTarget;

            let ADPreSelectedPerson = scoreMarkersXPos[Math.floor(this.selectedPersonScore.AD)] + ADCorrectionTarget;

            let ADPosSelectedPerson = [
                ADPreSelectedPerson - 16,
                ADPreSelectedPerson,
                ADPreSelectedPerson + 16
            ];

            let polyTarget = [
                {'x': ADPosSelectedPerson[0] + settings.xTranslation, 'y': 234 + settings.yTranslation},
                {'x': ADPosSelectedPerson[1] + settings.xTranslation, 'y': 217 + settings.yTranslation},
                {'x': ADPosSelectedPerson[2] + settings.xTranslation, 'y': 234 + settings.yTranslation}
            ];

            svg.append('polygon').data([polyTarget]).attr('points', function (d) {
                // tslint:disable-next-line: no-shadowed-variable
                return d.map(function (d) {
                    return [d.x, d.y].join(',');
                }).join(' ');
            }).style('fill', settings.color.paleGray2);
        }

        let frac = this.scores.AD - Math.floor(this.scores.AD);
        let ADCorrection = 60 * frac;

        let ADPre = scoreMarkersXPos[Math.floor(this.scores.AD)] + ADCorrection;

        let ADPos = [
            ADPre - 16,
            ADPre,
            ADPre + 16
        ];

        let poly = [
            {'x': ADPos[0] + settings.xTranslation, 'y': 234 + settings.yTranslation},
            {'x': ADPos[1] + settings.xTranslation, 'y': 217 + settings.yTranslation},
            {'x': ADPos[2] + settings.xTranslation, 'y': 234 + settings.yTranslation}
        ];

        svg.append('polygon').data([poly]).attr('points', function (d) {
            // tslint:disable-next-line: no-shadowed-variable
            return d.map(function (d) {
                return [d.x, d.y].join(',');
            }).join(' ');
        }).style('fill', settings.color.ngOrange);

        if (this.messageOverhead) {
            if (!this.hasScores) {
                let text;
                let TextXPosition;

                if (!this.wasRequest ||
                    !this.stateService.hasPersonality ||
                    (this.requestArr && this.requestArr[0] && this.requestArr[0].type && !this.requestArr[0].type.includes('mpo'))) {
                    text = this.translate.instant('commons.none');
                    TextXPosition = 340;
                } else {
                    text = this.translate.instant('people.commons.notCompleted');
                    TextXPosition = 250;
                }

                svg.append('text').attr('x', TextXPosition).attr('y', 175).text(text).attr('font-family', settings.fonts.general.family).attr('font-size', 50).attr('fill', settings.color.inactiveTitle);
            } else if (this.request) {
                let notCompleted = this.translate.instant('people.commons.notCompleted');
                let xPos = 131;
                if (this.lang === 'en') {
                    xPos = 150;
                }
                svg.append('text').attr('x', xPos).attr('y', 155).text(notCompleted).attr('font-family', settings.fonts.general.family).attr('font-size', 50).attr('fill', settings.color.inactiveTitle);
            }
        }

        return svg;
    }

    

    /**
     * This method is the preamble of the createMpoGraph. The later is concerned with D3 drawing, this one is concerned with
     * setting the default values for the various parameter of the graph and that, at one place.
     *
     * @returns {any} - Returns an object with the default parameters.
     */

    setting() {

        let settings: any = {
            xTranslation: 0,
            yTranslation: 20,
            xLimit: 780,
            yLimit: 345
        };

        if (this.reportConfig) {
            settings.xTranslation = 5;
            settings.yTranslation = 40;
            settings.xLimit = 940;
            settings.yLimit = 375;
        }

        if (this.rmScore) {
            settings.xTranslation = 5;
            settings.yTranslation = 40;
            settings.xLimit = 1070;
            settings.yLimit = 375;
        }

        settings.graphics = {};

        settings.graphics.bottomScoreLineStroke = (!this.hasScores) ? 3 : 1; // TODO: Substitute the condition

        settings.fonts = {general: {}};

        settings.fonts.general.family = 'sans-serif';
        settings.fonts.general.size = 26;

        settings.color = {};
        let inactiveColor = 'rgb(217, 217, 217)';
        let inactive = (this.request || !this.hasScores);

        settings.color.black = (inactive) ? inactiveColor : 'rgb(0,0,0)';
        settings.color.paleGray = (inactive) ? inactiveColor : 'rgb(217, 217, 217)';
        settings.color.paleGray2 = (inactive) ? inactiveColor : 'rgb(170, 170, 170)';
        settings.color.darkGray = (inactive) ? inactiveColor : 'rgb(85, 85, 85)';
        settings.color.ngOrange = (inactive) ? inactiveColor : 'rgb(227, 94, 36)';
        settings.color.outerBorder = (inactive) ? inactiveColor : 'rgb(206,206,206)';
        settings.color.inactiveTitle = 'rgb(86, 86, 86)';
        settings.color.prbPending = 'rgb(227, 94, 36)';

        if (this.section === 'jobSatisfaction') {
            settings.color.ngOrange = (inactive) ? inactiveColor : 'rgb(85, 85, 85)';
        }

        return settings;
    }

    /**
     * This method compute the delta of the interaction and order them in the proper way for display in MPO report.
     *
     * @param traitScores - object of scores
     * @returns {Array<Array<any>>} - an array containing the label of the interaction e.g. A/E and the delta for that
     *                                interaction
     */

    orderInteraction(traitScores) {
        const traits = ['A', 'E', 'P', 'S'];
        let deltas: any = {};

        // Preliminary ordering along with proper position of traits i.e.,
        // The highest first and, if equal, the alphabetical order primes.

        traits.forEach((trait, index) => {
            for (let i = index + 1; i < 4; i++) {
                let label = trait + '_' + traits[i];
                if (traitScores[traits[index]] < traitScores[traits[i]]) {
                    label = traits[i] + '_' + trait;
                }
                deltas[label] = Math.abs(traitScores[traits[index]] - traitScores[traits[i]]);
            }
        });

        // Create an array for sorting with respect to label of the first trait , label of the second trait
        // and the interaciton score.

        let sortedDeltas = [];

        for (let delta in deltas) { if (deltas.hasOwnProperty(delta)) { sortedDeltas.push(
            [delta.split('_')[0], delta.split('_')[1], deltas[delta]]
        );
                                  }
        }

        // Perfom final ordering.

        sortedDeltas.sort(function (a, b) {
            if (b[2] === a[2] && b[1] === a[1]) { return b[0] - a[0]; }
            if (b[2] === a[2]) { return b[1] - a[1]; }
            return b[2] - a[2];
        });

        let sortedInts: Array<Array<any>> = [];

        sortedDeltas.forEach((interaction) => {
            sortedInts.push([
                interaction[0] + '/' + interaction[1],
                String(interaction[2])
            ]);
        });

        return sortedInts;
    }

    orderDeltasPrbAndTraits(prbScores, traitScores) {
        const traits = ['OR', 'SE', 'A', 'E', 'P', 'S', 'AD'];
        let orderedDeltas = [];
        traits.forEach(trait => {
            orderedDeltas.push([
                trait,
                String(prbScores[trait] - traitScores[trait])
            ]);
        });

        return orderedDeltas;
    }

    cleanScores(scores) {
        scores.OR = this.cleanScore(+scores.OR);
        scores.SE = this.cleanScore(+scores.SE);
        scores.A = this.cleanScore(+scores.A);
        scores.E = this.cleanScore(+scores.E);
        scores.P = this.cleanScore(+scores.P);
        scores.S = this.cleanScore(+scores.S);

        return scores;
    }

    cleanScore(score) {
        if (typeof score !== 'number' || score < 0) {
            score = 0;
        }

        if (score > 10) {
            score = 10;
        }

        return score;
    }

    resetInputs(changes) {
        if (!changes.lang && this.targetScore === false && this.selectedPersonScore === false) {
            this.hasScores = true;
            this.wasRequest = false;
            for (let variable of ['width', 'scores']) {
                if (!changes[variable]) {
                    this[variable] = undefined;
                }
            }

            for (let variable of ['scSize', 'comparisonScores', 'request', 'graphboard', 'rmScore']) {
                if (!changes[variable]) {
                    this[variable] = false;
                }
            }
        }
    }
}
