import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {TextHelper} from '../../../../shared/misc/text.helper';
import {PersonService} from '../../../services/person.service';
import {TestAndReportHandlingService} from '../../../services/test-and-report-handling.service';
import {Subscription} from 'rxjs';
import {StateService} from '../../../../core/services/state/state.service';

@Component({
    selector: 'app-people-report-iac',
    templateUrl: './people-report-iac.component.html',
    styleUrls: ['./people-report-iac.component.scss']
})
export class PeopleReportIacComponent implements OnInit, OnDestroy {

    @Input() mobile = false;

    report = {
        IacVsRaText: null,
        texts: {}
       
    };

    titleSep: string;
    dateFormat: string;
    subscriptions = new Subscription();

    constructor(
        public translate: TranslateService,
        private apiJobsService: ApiJobsService,
        private testAndReportHandlingService: TestAndReportHandlingService,
        public changeDetectorRef: ChangeDetectorRef,
        public person: PersonService,
        public stateService: StateService
    ) {
    }

    ngOnInit() {
        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.dateFormat = TextHelper.dateFormat(this.translate.currentLang);
        

        if (this.person.iacReport) {
            this.getIacReport();
        }

        this.stateService.people.stateChanged$.subscribe(
            res => {
                if (res && (res.personId || res.personDefChanged)) {
                    this.getIacReport();
                    if (res) {
                        this.titleSep = TextHelper.checkTitleSep(res.lang);
                        this.dateFormat = TextHelper.dateFormat(res.lang);
                    }
                    this.changeDetectorRef.markForCheck();
                }
            }
        );

        this.translate.onLangChange.subscribe(
            (res) => {
                this.getIacReport();
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                    this.dateFormat = TextHelper.dateFormat(res.lang);
                }
            }
        );
    }

    getIacReport() {
        this.subscriptions.add(this.testAndReportHandlingService.getReport('iac', this.translate.currentLang).subscribe(
            report => {
                this.report = report;
                this.changeDetectorRef.markForCheck();
            },
            err => console.log(err)
        ));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
