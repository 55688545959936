import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '../../../core/services/state/state.service';
import * as _ from 'lodash';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {TextHelper} from '../../../shared/misc/text.helper';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {AccountTypesHelper} from '../../commons/AccountTypesHelper';
import {AdminModalHandlingService} from '../../services/admin-modal-handling.service';
import {ProductsHelper} from '../../helpers/products.helper';
import {AccountService} from '../../services/account.service';
import {SubAccountService} from '../../services/sub-account.service';
import {take, takeLast} from 'rxjs/operators';
import * as moment from 'moment/moment';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter, CUSTOM_DATE_FORMATS } from '../../../shared/helpers/custom-date-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
    selector: 'app-administration-list-transactions',
    templateUrl: './administration-list-transactions.component.html',
    styleUrls: ['./administration-list-transactions.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    ],
})
export class AdministrationListTransactionsComponent implements OnInit {

    @Input() accountNumber;
    @Input() subAccountNumber;

    public loading = false;

    actualPage = 1;
    maxPerPage = 10;
    /** mod this */
    maxPage: number;
    numberOfLastPageReached = 0;

    public dropDownSettings: IDropdownSettings = {
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 3,
        enableCheckAll: false
    };

    filters: any = {
        selectedTransactions: {
            'adjustment': true,
            'creation_subAccount': true,
            'transfer_credits': true,
            'creation_demo': true,
            'reports': true,
            'formations': true,
            'annual_fees': true,
            'buying_credits': true,
            'buying_programs': true
        },
        subAccount: null,
        startDate: null,
        endDate: null
    };

    // levels.en.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('subsidiary', 'en', 'object'));

    value = '';

    listTitles = {
        fr: [
            'Nom',
            'Date',
            'Sous-compte',
            'Crédits'
        ],
        en: [
            'Name',
            'Date',
            'SubAccount',
            'Credits'
        ]
    };

    transactions: any;
    dateFormat;

    constructor(
        protected translate: TranslateService,
        protected stateService: StateService,
        protected apiAdministration: ApiAdministrationService,
        protected cd: ChangeDetectorRef,
        protected accountService: AccountService,
        protected subAccountService: SubAccountService,
        protected adminModalHandlingService: AdminModalHandlingService
    ) {
    }

    ngOnInit(): void {

        this.filters.subAccount = (this.subAccountService.id !== 0) ? this.subAccountService.id : null;
        this.dateFormat = TextHelper.dateFormat(this.translate.currentLang);
        this.translate.onLangChange.subscribe(
            (res) => {
                this.dateFormat = TextHelper.dateFormat(res.lang);
            }
        );
        /**
        this.stateService.admin.stateChanged$.pipe(takeLast(1)).subscribe(
            (res) => {
                if (res) {
                    if (res && !res.reset && res.setClient !== this.accountService.id) {
                        this.filterTransactions();
                        this.cd.markForCheck();
                    }
                }
            }
        );*/
        this.accountService.productDescriptionChanged$.subscribe(
            (res) => {
                if (res) {
                    this.filterTransactions();
                }
            });
        let params: any = {search: '', subAccount: [this.filters.subAccount ?? null]};
        this.fetchTransactions(params);
    }

    fetchTransactions(params = null) {
        this.loading =  true;
        this.apiAdministration.transactions([this.accountNumber], params).subscribe(
            res => {
                this.setupTransactions(res);
                this.loading =  false;
                this.cd.markForCheck();
            }
        );
    }


    setupTransactions(res) {
        this.transactions = res['data'];
        if (this.maxPage !== Math.ceil(res.count / this.maxPerPage)) {
            this.actualPage = 1;
            this.maxPage = Math.ceil(res.count / this.maxPerPage);
            this.numberOfLastPageReached = 0;
        }
        while (this.transactions.length < 10) {
            this.transactions.push({
                sku: 'void',
                date: null,
                subAccountData: {
                    name: null
                },
                amount: null
            });
        }
        this.cd.markForCheck();
        this.loading =  false;
    }

    openFiltersModal() {
        this.adminModalHandlingService.openTransactionsFilterModal({
            selectedFilters: this.filters
        });
        this.adminModalHandlingService.transactionsFilterRef.content.close.subscribe(res => {
            this.filters = res;
            this.filterTransactions();
        });
    }

    openTransactionsEditModal(transaction) {
        this.adminModalHandlingService.openTransactionsEditModal({
            initialTransaction: transaction,
            isSuperAdmin: this.stateService.session.sessionData.permissions.roles.includes('ROLE_SUPER_ADMIN')
        });
        if (this.stateService.session.sessionData.permissions.roles.includes('ROLE_SUPER_ADMIN') && this.subAccountNumber === 0) {
            this.adminModalHandlingService.transactionsEditRef.content.edit.subscribe(res => {
                if (res) {
                    this.editTransactions(res);
                }
            });
            this.adminModalHandlingService.transactionsEditRef.content.delete.subscribe(res => {
                if (res) {
                    this.deleteTransaction(res.id, res.hardDelete, res.origin);
                }
            });
        }

    }

    editTransactions(transaction) {
        this.apiAdministration.updateTransaction([transaction.id], [
            {
                sku: transaction.sku ?? undefined,
                subAccount: transaction.subAccount ?? undefined,
                date: transaction.date ?? undefined,
                amount: transaction.amount ?? undefined,
                extraDescription: transaction.extraDescription ?? undefined,
                hardDelete: transaction.hardDelete ?? undefined
            }
        ]).subscribe(
            res => {
                this.stateService.admin.stateChanged.next({refreshComponent: true});
                this.filterTransactions();
            }
        );

    }

    handleUnlimited(amount) {
        if (parseInt(amount, 10) > 9998) {
            return (this.translate.currentLang === 'fr') ? 'Illimité' : 'Unlimited';
        }
        return amount;
    }

    deleteTransaction(id, hardDelete, origin) {
        this.apiAdministration.deleteTransaction([id], {
            hardDelete: hardDelete,
            origin: origin
        }).subscribe(
            () => {
                this.stateService.admin.stateChanged.next({refreshComponent: true});
                this.filterTransactions();
            }
        );
    }

    notSpecified() {
        if (this.translate.currentLang === 'fr') {
            return 'Non spécifié';
        } else {
            return 'Not specified';
        }
    }

    setFilterDates(params = null) {
        if (params === null) {
            params = this.filters;
        }

        for (let whichDate of ['startDate', 'endDate']) {
            if (this.filters[whichDate] !== null && this.filters[whichDate] !== undefined) {
                let date = new Date(this.filters[whichDate]);
                if (!isNaN(date.getTime())) {
                    let year = date.getFullYear();
                    let month = ('0' + (date.getMonth() + 1)).slice(-2);
                    let day = ('0' + date.getDate()).slice(-2);
                    params[whichDate] = `${year}-${month}-${day}`;
                } else {
                    params[whichDate] = this.filters[whichDate];
                }
            }
        }
        return params;
    }

    addTrailingZeroes(value) {
        if (value < 10) {
            return '0' + value;
        } else {
            return value;
        }
    }

    goToPage(factor) {
        this.actualPage = this.actualPage + factor;
        // Last page process
        if (this.isLastPage) {
            this.numberOfLastPageReached = this.numberOfLastPageReached + 1;
        }

        this.fetchTransactions({
            search: '',
            subAccount: [this.filters.subAccount ?? null],
            startDate: this.setFilterDates().startDate ?? null,
            endDate: this.setFilterDates().endDate ?? null,
            skip: (this.actualPage - 1) * this.maxPerPage
        });
    }

    cellProperties(columnIndex) {
        let styleObject = {
            width: '',
            vertical_align: '',
            text_align: ''
        };
        switch (columnIndex) {
            case 0:
                styleObject.width = '300px';
                return styleObject;
            case 1:
                styleObject.width = '50px';
                return styleObject;
            default:
                styleObject.width = '100px';
                return styleObject;
        }
    }

    filterTransactions() {

        let selectedTransactions = '';
        for (let transactions in this.filters.selectedTransactions) {
            if (this.filters.selectedTransactions[transactions]) {
                selectedTransactions += '.' + transactions;
            }
        }
        let params: any = {
            transactionTypes: selectedTransactions,
            subAccount: [this.filters.subAccount ?? null],
            startDate: this.filters.startDate,
            endDate: this.filters.endDate
        };

        this.setFilterDates(params);
        this.fetchTransactions(params);
    }

    get isSuperAdmin() {
        return this.sessionPermissions.roles.includes('ROLE_SUPER_ADMIN');
    }

    get isLastPage(): boolean {
        return (this.actualPage === this.maxPage);
    }

    get sessionPermissions() {
        return _.get(this.stateService, 'session.sessionData.permissions');
    }


    protected readonly ProductsHelper = ProductsHelper;
}
