import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    OnDestroy,
    ViewChild, AfterViewInit
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ApiRightMatchesService } from 'src/app/analysis/right-matches/services/api-right-matches.service';
import { ApiIdwMatricesService } from 'src/app/analysis/idw-matrices/services/api-idw-matrices.service';
import { ApiPeopleService } from 'src/app/people/services/api-people.service';
import { ComponentList } from 'src/app/shared/classes/components/component_list';
import { UserService } from '../../../../core/services/user/user.service';
import { PeopleList } from '../../../services/people_list.service';
import { StateService } from '../../../../core/services/state/state.service';
import { AlertService } from 'src/app/shared/alert/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ResponsiveHelper } from 'src/app/shared/helpers/responsive.helper';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PeopleModalHandlingService } from '../../../services/people-modal-handling.service';
import { PeopleSearchFiltersService } from '../../../services/people-search-filters.service';
import { PersonService } from '../../../services/person.service';
import {PeopleMultiselectService} from '../../../services/people-multiselect.service';
declare var $: any;

@Component({
    selector: 'app-people-list',
    templateUrl: './people-list.component.html',
    styleUrls: ['./people-list.component.scss'],
    animations: [
        trigger('selected', [
            state('none', style({
                display: 'none',
                transform: 'scale(0)'
            })),
            state('some', style({
                display: '',
                transform: 'scale(1)'
            })),
            transition('none => some', animate('120ms ease-in')),
            transition('some => none', animate('120ms ease-out'))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleListComponent extends ComponentList implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('gSidebar') gSidebar;
    activationData: any;
    resendData: any;
    listWidth: any;
    sideWidth: any;
    isSelected = 'none';
    totalItems = 0;
    url: string;

    displayConstant = {
        adjustment: { left: -5, right: -14 },
        widthRatio: 2 / 3,
        leftColumnWidth: 440
    };
    multiselectData: any = {
        actionType: null,
        ids: []
    };

    private subscriptions = new Subscription();
    lastStateChange: any;
    showCarousel = true;
    basePath: string;
    dontShowAgain: boolean = false; // New property to track checkbox state

    constructor(
        public user: UserService,
        public person: PersonService,
        public peopleList: PeopleList,
        private alertService: AlertService,
        protected translate: TranslateService,
        protected stateService: StateService,
        private apiPeople: ApiPeopleService,
        private apiRightMatchesService: ApiRightMatchesService,
        private apiIdwMatrices: ApiIdwMatricesService,
        protected router: Router,
        protected route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        protected deviceService: DeviceDetectorService,
        public peopleModalHandlingService: PeopleModalHandlingService,
        public peopleSearchFilter: PeopleSearchFiltersService,
        public peopleMultiselect: PeopleMultiselectService
    ) {
        super(stateService, translate, router, deviceService);
        this.peopleList._opened = !this.smallSize;
        this.peopleList.checkReportType();

    }

    ngOnInit() {
        this.peopleList.clickMode = false;
        this.peopleList.state.stateChanged.next({
            refreshList: true
        });
        this.peopleMultiselect.action = null;
        this.peopleList.resetList();
        if (this.isMobile && this.router.url) {
            this.router.navigate(['/m' + this.router.url]).then(() => {
            });
        }

        this.url = this.router.url.split(';')[0];
        this.url = this.url.substr(1);
        // we have to check archive settings before calling other functions related to the state
        this.peopleList.checkArchiveSettings();
        this.peopleList.setPersonId();
        this.peopleList.checkSideBarStatus();
        this.peopleList.initListAction();
        this.peopleList.checkJobList();
        this.peopleList.testAccessPermissions();

        this.peopleList.getRecordList(true);

        this.subscriptions.add(this.translate.onLangChange.subscribe(() => {
            this.peopleList.getRecordList(true);
        }));

        // Set list width
        this.computeListWidth();

        // check items list without selection
        this.peopleList.getList(
            { listSearchFilters: {} },
            0,
            null, false, false, this.actualModule.archive
        ).listObservable.subscribe(list => {
            this.totalItems = list.count;
            this.changeDetectorRef.markForCheck();
        });

        this.subscriptions.add(this.stateService.people.stateChanged$.subscribe(
            (res) => {
                if (res) {
                    if (res.newPerson || res.canceled || res.refreshList) {
                        this.refreshList();
                    }
                    if (res.targetReport) {
                        this.stateService.people.reportType = res.targetReport;
                        this.stateService.people.reportSubType = 'traits';

                        let stateChangedPayload: any = {
                            'reportChange': true
                        };

                        if (res.targetReport === 'communication') {
                            stateChangedPayload['commTraits'] = true;
                        }
                        this.stateService.people.stateChanged.next(stateChangedPayload);
                    }
                    this.changeDetectorRef.markForCheck();
                }
            }
        ));
        this.setBasePath();
        this.translate.onLangChange.subscribe(() => {
            this.setBasePath();
        });
        // Check screen dimensions
        if (window.innerHeight <= 750 || window.innerWidth <= 1100) {
            this.showCarousel = false;
        } else {
            this.checkSawCarousel();
        }
        this.changeDetectorRef.markForCheck();
        }

    setBasePath() {
        const lang = this.translateService.currentLang || 'en'; // default to 'en' if not set
        this.basePath = `assets/carousel/carousel-desktop/${lang}/`;
    }

    setSearchFilter(event) {
        this.peopleSearchFilter.setSearchFilter(event);
        this.refreshList();
    }

    setOrder(event) {
        this.peopleList.setOrder(event);
        this.refreshList();
    }

    openSurveyModal() {
        // reset multiselect
        this.peopleList.revertToNews();
        this.peopleList.cleanMultiselectMode(false);
        this.peopleModalHandlingService.openSurveyModal();
    }

    refreshList() {
        this.peopleList.getRecordList(true);
    }

    onSidebarStatusChange(opened) {
        if (this.gSidebar && !opened && !this.closed) {
            this.peopleList._opened = true;
            this.gSidebar.open();
        }
    }

    onResize(): void {
        // re-Set list width
        this.computeListWidth();
        // Handle window width
        this.peopleList._opened = !this.smallSize;
        this.closed = this.smallSize;

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Close people sidebar tablet device
     */
    closePeopleSidebar(closePeopleSidebar: boolean): void {
        if (closePeopleSidebar && this.peopleList._opened && this.smallSize) {
            this.peopleList._opened = false;
            this.closed = true;
        }
    }

    personSelected(event) {
        this.closed = false;
        this.peopleList.personSelected(event);
    }

    //// Modals handling ///////////////////////////////////////////////////////////////////////////////////////////////

    openRequestSendMpoModal(sendMpoData): void {
        if (sendMpoData && sendMpoData.person && sendMpoData.person.id) {
            this.person.personToDisplay = sendMpoData.person;
        }
        this.peopleList.revertToNews();
        this.peopleList.cleanMultiselectMode(false);
        if (this.person.hasAnIacQuestionnaireSent) {
            let wType = this.translateService.instant('commons.cai');
            let txtWarning = {
                paragraph: this.translateService.instant('commons.questionnaireAlreadySent', { qType: wType }),
                texth1: this.translateService.instant('commons.alertWarning'),
                position: 'center-center',
                template: 'freezeAction'
            };
            this.alertService.setConfig(txtWarning);
        } else {
            this.peopleModalHandlingService.openRequestSendMpoModal(sendMpoData);
        }
    }

    openRequestResendMpoModal(resendData) {
        this.peopleList.revertToNews();
        this.peopleList.cleanMultiselectMode(false);
        if (resendData && resendData.person && resendData.person.id) {
            this.person.personToDisplay = resendData.person;
        }
        this.openRequestResendModal(resendData);
    }

    openRequestResendIacModal(resendData) {
        this.peopleList.revertToNews();
        this.peopleList.cleanMultiselectMode(false);
        if (resendData && resendData.person && resendData.person.id) {
            this.person.personToDisplay = resendData.person;
        }
        this.openRequestResendModal(resendData);
    }

    openRequestResendModal(resendData): void {
        this.peopleList.revertToNews();
        this.peopleList.cleanMultiselectMode(false);
        if (resendData && resendData.person && resendData.person.id) {
            this.person.personToDisplay = resendData.person;
        }
        this.peopleList.cleanMultiselectMode(false);
        this.peopleModalHandlingService.openRequestResendModal(resendData);
    }

    openRequestSendIacModal(sendIacData): void {
        this.peopleList.revertToNews();
        this.peopleList.cleanMultiselectMode(false);
        if (sendIacData && sendIacData.person && sendIacData.person.id) {
            this.person.personToDisplay = sendIacData.person;
        }
        this.peopleList.cleanMultiselectMode(false);
        if (this.person.hasAnMpoQuestionnaireSent) {
            let txtWarning = {
                paragraph: this.translateService.instant('commons.questionnaireAlreadySent', { qType: 'MPO' }),
                texth1: this.translateService.instant('commons.alertWarning'),
                position: 'center-center',
                template: 'freezeAction'
            };
            this.alertService.setConfig(txtWarning);
        } else {
            this.peopleModalHandlingService.openRequestSendIacModal(sendIacData);
        }
    }

    openPdfModal(): void {
        this.peopleList.revertToNews();
        this.peopleList.cleanMultiselectMode(false);
        this.peopleModalHandlingService.openPdfModal();
    }

    openReportActivationModal(activationData): void {
        this.peopleList.revertToNews();
        this.peopleList.cleanMultiselectMode(false);
        this.peopleList.cleanMultiselectMode(false);
        this.peopleModalHandlingService.openReportsActivationModal(activationData);
    }

    /// END - Modal ending /////////////////////////////////////////////////////////////////////////////////////////////

    actOnActivation(activationData): void {
        if (activationData.invalid) {
            let errorMessage = this.translate.instant('commons.invalidReport');
            let errorTitle = this.translate.instant('commons.alertWarning');
            let warnMessage = {
                paragraph: errorMessage,
                texth1: errorTitle,
                position: 'center-center',
                template: 'warning'
            };
            this.alertService.setConfig(warnMessage);
        } else {
            this.openReportActivationModal(activationData);
        }
        return;
    }

    computeListWidth() {
        let checkWidth = ResponsiveHelper.computeListWidth(window.innerWidth, this.displayConstant);
        this.sideWidth = checkWidth.sideWidth;
        this.listWidth = checkWidth.listWidth;
        this.changeDetectorRef.markForCheck();
    }



    checkSawCarousel() {
        const localStorageKey = 'sawCarouselDuo';
        const localAccepted = localStorage.getItem(localStorageKey);
        if (localAccepted === 'true') {
            // User has accepted terms previously
            this.showCarousel = false;
        } else {
            // Check with the API
            const apiAccepted = this.person.hasSawCarousel;
            console.log('API confirms AI Carrousel acceptance:', apiAccepted);
            if (apiAccepted) {
                // API confirms acceptance
                this.showCarousel = false;
                localStorage.setItem(localStorageKey, 'true');
            }
        }
    }

    closeCarousel() {
        this.showCarousel = false;
        if (this.dontShowAgain) {
            this.saveSawCarrousel();
        }
    }

    saveSawCarrousel() {
        const localStorageKey = 'sawCarouselDuo';
        localStorage.setItem(localStorageKey, 'true');

        // Replace 'user-id' with the actual user identifier required by your API
        const userId: string = this.stateService.session.sessionData.userData.id;
        const urlParams: [string] = [userId];

        this.subscriptions.add(
            this.apiPeople.setSawAiCarousel(urlParams).subscribe(
                (response) => {
                    console.log('AI Carrousel acceptance successfully saved to the backend.');
                },
                (error) => {
                    console.error('Error saving AI Carrousel acceptance to the backend:', error);
                }
            )
        );
    }

    onCheckboxChange(event: Event) {
        const checkbox = event.target as HTMLInputElement;
        this.dontShowAgain = checkbox.checked;
    }

    ngAfterViewInit() {
        $('#myCarousel').carousel();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
     }

}
