import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {EvaluationRequest} from '../models/evaluation-request.model';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Rest} from '../../core/services/api/rest';
import {EnvService} from '../../app/services/env.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {StateService} from '@core/services/state/state.service';

@Injectable({
    providedIn: 'root'
  })
export class ApiPeopleService extends Rest {
    constructor(
        protected http: HttpClient,
        protected translateService: TranslateService,
        protected environment: EnvService,
        protected stateService: StateService
    ) {
        super(http, environment);
    }

    /**
     *
     * @param urlParams
     * @param dataParams
     * @returns {Observable<any>}
     */
    emailEvaluationSurvey(urlParams: [any], dataParams: [any, any, any]): Observable<any> {
        return this.apiGet(
            'email/evaluation-survey',
            urlParams,
            dataParams,
            ['emailTo', 'message', 'isUserIdentified']
        );
    }

    setAcceptAiTerms(urlParams: [string]) {
        return this.apiPut(
            'user/UrlParameter0/acceptaiterms',
            urlParams,
            null
        );
    }

    setSawAiCarousel(urlParams: [string]) {
        return this.apiPut(
            'user/UrlParameter0/aicarousel',
            urlParams,
            null
        );
    }

    saveAiContext(data: any): Observable<any> {
        return this.apiPost(
            'store-questions',
            null,
            data
        );
    }

    questionAi(data, report = 'traits'): Observable<any> {

        let q = '{';
        q += '"question": "' + encodeURIComponent(data.question) + '",';
        q += '"current_lang": "' + data.currentLang + '",';
        q += '"experimental": "' + data.experimental + '",';
        if (report === 'traits' || report === 'perceptions') {
            q += '"personality_scores":' + JSON.stringify(data.personalityScores);
            if (report === 'perceptions') {
                q += ',';
            }
        }
        if (report === 'talents') {
            q += '"talents_scores": ' + JSON.stringify(data.talentsScores);
        } else if (report === 'perceptions') {
            q += '"pcr_scores": ' + JSON.stringify(data.prbScores);
        } else if (report === 'iac') {
            q += '"iac_scores": ' + JSON.stringify(data.iacScores);
        } else if (report === 'duo') {
            q += '"person_1_scores":' + JSON.stringify(data.person_1_scores) + ',' +
                '"person_2_scores":' + JSON.stringify(data.person_2_scores) + ',' +
                '"person_1_name":' + JSON.stringify(encodeURIComponent(data.person_1_name)) + ',' +
                '"person_2_name":' + JSON.stringify(encodeURIComponent(data.person_2_name));
        }


        q += '}';
        // console.log(q);
        q = btoa(q);
        let endpoint = 'get_personality_analysis';
        if (report === 'talents') {
            endpoint = 'get_talents_analysis';
        }
        if (report === 'perceptions') {
            endpoint = 'get_pcr_analysis';
        }
        if (report === 'iac') {
            endpoint = 'get_iac_analysis';
        }
        if (report === 'duo') {
            endpoint = 'get_duo_analysis';
        }
        // console.log(q);
        return this.http.get(
             'https://ai.ngenioconnect.com/' + endpoint + '?q=' + q
        ).pipe(
            catchError(error => {
                console.error('Error occurred:', error);
                return throwError(error);
            })
        );
    }

    // This clones the clients method of api-administratio-service
    clientForTransfer(dataParams: Array<any>): Observable<any> {
        return this.apiGet(
            'accounts',
            null,
            dataParams,
            ['@fromObject']
        );
    }

    /**
     *
     * @param dataParams
     * @returns {Observable<any>}
     */
    requestToken(dataParams: Array<string>): Observable<any> {
        return this.apiGet(
            'evaluation/request/token',
            null,
            dataParams,
            ['recordId']
        );
    }

    /**
     *
     * @param data
     * @returns {Observable<any>}
     */
    evaluationRequest(data: EvaluationRequest): Observable<any> {
        // translate for API
        let iacIndex = data.type.indexOf('iac');
        if (iacIndex !== -1) {
            data.type[iacIndex] = 'ra';
            data.hasSurveyRa = true;
        }

        return this.apiPost(
            'evaluation/request',
            null,
            data
        );
    }

    /**
     *
     * @param {Array<any>} dataParams
     * @returns {Observable<any>}
     */
    evaluationRequestNext(dataParams: [string, number]): Observable<any> {
        return this.apiGet(
            'evaluation/request/next',
            null,
            dataParams,
            ['recordId', 'requestIndex']
        );
    }

    /**
     *
     * @param {Array<any>} dataParams
     * @returns {Observable<any>}
     */
    evaluationRequestResend(dataParams: [string, number]): Observable<any> {
        return this.apiGet(
            'evaluation/request/resend',
            null,
            dataParams,
            ['recordId', 'requestIndex']
        );
    }

    /**
     *
     * @param urlParams
     * @param dataParams
     * @returns {Observable<any>}
     */
    product(urlParams: [string], dataParams: Array<any>): Observable<any> {
        const action = 'enable'; // defaulted
        return this.apiGet(
            'product/UrlParameter0/' + action,
            urlParams,
            dataParams,
            // ['recordId', 'subAccount']
            ['@fromObject']
        );
    }

    /**
     *
     * @param urlParams
     * @returns {Observable<any>}
     */
    record(urlParams: Array<string>): Observable<any> {
        return this.apiGet(
            'record',
            urlParams,
            null,
            null
        );
    }

    /**
     *
     * @param {Array<any>} dataParams
     * @returns {Observable<any>}
     */
    records(dataParams: Array<any>): Observable<any> {
        dataParams[0]['lang'] = this.translateService.currentLang;
        return this.apiGet(
            'records',
            null,
            dataParams,
            ['@fromObject']
        );
    }

    /**
     *
     * @param urlParams
     * @returns {Observable<any>}
     */
    deleteRecord(urlParams: Array<string>): Observable<any> {
        return this.apiDelete(
            'record',
            urlParams
        );
    }

    /**
     *
     * @param urlParams
     * @param dataParams
     * @returns {Observable<any>}
     */
    reportEvaluation<T, U>(urlParams: Array<string>, dataParams: any): Observable<any> {
        return this.apiGet(
            'report/evaluation',
            urlParams, // type, either mpo or idw
            dataParams,
            ['recordId', 'parts', 'indexEvaluation', 'indexPRB', 'language', 'reportType']
        );
    }

    /**
     *
     * @param urlParams
     * @param dataParams
     * @returns {Observable<any>}
     */
    reportRa<T, U>(urlParams: Array<string>, dataParams: any): Observable<any> {
        return this.apiGet(
            'report/ra',
            urlParams,
            dataParams,
            ['language']
        );
    }

    /**
     *
     * @param urlParams
     * @param dataParams
     * @returns {Observable<any>}
     */
    reportEvaluationSatellite(urlParams: Array<string>, dataParams: Array<any>): Observable<any> {
        return this.apiGet(
            'report/satellite/record',
            urlParams, // Id for the record
            dataParams,
            ['indexEvaluation', 'indexProfiler', 'language', 'parts']
        );
    }

    /**
     *
     * @param {Array<any>} dataParams
     * @returns {Observable<any>}
     */
    reportEmail(dataParams: Array<any>): Observable<any> {
        return this.apiGet(
            'email/report',
            null,
            dataParams,
            ['emailTo', 'subject', 'message', 'pdfLink', 'name', 'origin', 'lang']
        );
    }

    /**
     *
     * @param urlParams
     * @param data
     * @returns {Observable<any>}
     */
    deleteRequest(urlParams: [string], data): Observable<any> {
        return this.apiPost(
            'record/UrlParameter0/delete-request',
            urlParams,
            data
        );
    }

    /**
     *
     * @param idList
     * @returns {Observable<any>}
     */
    deleteRecords(idList: string[]): Observable<any> {
        return this.apiPost(
            'records/delete',
            null,
            {idList: idList}
        );
    }

    /**
     *
     * @param idList
     * @returns {Observable<any>}
     */
    checkDelete(idList: Array<any>): Observable<any> {
        return this.apiPost(
            'records/check-delete',
            null,
            {idList: idList}
        );
    }

    /**
     *
     * @param urlParams
     * @param data
     * @returns {Observable<any>}
     */
    editPerson(urlParams: Array<string>, data): Observable<any> {
        return this.apiPut(
            'record',
            urlParams,
            data
        );
    }

    /**
     *
     * @param urlParams
     * @param data
     * @returns {Observable<any>}
     */
    editSatellite(urlParams: Array<string>, data): Observable<any> {
        return this.apiPut(
            'satellite',
            urlParams,
            data
        );
    }
}
